const generateKeyValue = ( list ) => {
    return list.map( ( val ) => {
        return { key: val, value: val.toUpperCase() };
    } );
};

const colors =  [
    {key: "abbey",  value: "#4C4F56" },
    {key: "acadia",  value: "#1B1404" },
    {key: "acapulco",  value: "#7CB0A1" },
    {key: "aero blue",  value: "#C9FFE5" },
    {key: "affair",  value: "#714693" },
    {key: "akaroa",  value: "#D4C4A8" },
    {key: "alabaster",  value: "#FAFAFA" },
    {key: "albescent white",  value: "#F5E9D3" },
    {key: "algae green",  value: "#93DFB8" },
    {key: "alice blue",  value: "#F0F8FF" },
    {key: "alizarin crimson",  value: "#E32636" },
    {key: "allports",  value: "#0076A3" },
    {key: "almond",  value: "#EED9C4" },
    {key: "almond frost",  value: "#907B71" },
    {key: "alpine",  value: "#AF8F2C" },
    {key: "alto",  value: "#DBDBDB" },
    {key: "aluminium",  value: "#A9ACB6" },
    {key: "amaranth",  value: "#E52B50" },
    {key: "amazon",  value: "#3B7A57" },
    {key: "amber",  value: "#FFBF00" },
    {key: "americano",  value: "#87756E" },
    {key: "amethyst",  value: "#9966CC" },
    {key: "amethyst smoke",  value: "#A397B4" },
    {key: "amour",  value: "#F9EAF3" },
    {key: "amulet",  value: "#7B9F80" },
    {key: "anakiwa",  value: "#9DE5FF" },
    {key: "antique brass",  value: "#C88A65" },
    {key: "antique bronze",  value: "#704A07" },
    {key: "anzac",  value: "#E0B646" },
    {key: "apache",  value: "#DFBE6F" },
    {key: "apple",  value: "#4FA83D" },
    {key: "apple blossom",  value: "#AF4D43" },
    {key: "apple green",  value: "#E2F3EC" },
    {key: "apricot",  value: "#EB9373" },
    {key: "apricot peach",  value: "#FBCEB1" },
    {key: "apricot white",  value: "#FFFEEC" },
    {key: "aqua deep",  value: "#014B43" },
    {key: "aqua forest",  value: "#5FA777" },
    {key: "aqua haze",  value: "#EDF5F5" },
    {key: "aqua island",  value: "#A1DAD7" },
    {key: "aqua spring",  value: "#EAF9F5" },
    {key: "aqua squeeze",  value: "#E8F5F2" },
    {key: "aquamarine",  value: "#7FFFD4" },
    {key: "aquamarine blue",  value: "#71D9E2" },
    {key: "arapawa",  value: "#110C6C" },
    {key: "armadillo",  value: "#433E37" },
    {key: "arrowtown",  value: "#948771" },
    {key: "ash",  value: "#C6C3B5" },
    {key: "asparagus",  value: "#7BA05B" },
    {key: "asphalt",  value: "#130A06" },
    {key: "astra",  value: "#FAEAB9" },
    {key: "astral",  value: "#327DA0" },
    {key: "astronaut",  value: "#283A77" },
    {key: "astronaut blue",  value: "#013E62" },
    {key: "athens gray",  value: "#EEF0F3" },
    {key: "aths special",  value: "#ECEBCE" },
    {key: "atlantis",  value: "#97CD2D" },
    {key: "atoll",  value: "#0A6F75" },
    {key: "atomic tangerine",  value: "#FF9966" },
    {key: "au chico",  value: "#97605D" },
    {key: "aubergine",  value: "#3B0910" },
    {key: "australian mint",  value: "#F5FFBE" },
    {key: "avocado",  value: "#888D65" },
    {key: "axolotl",  value: "#4E6649" },
    {key: "azalea",  value: "#F7C8DA" },
    {key: "aztec",  value: "#0D1C19" },
    {key: "azure",  value: "#315BA1" },
    {key: "azure radiance",  value: "#007FFF" },
    {key: "baby blue",  value: "#E0FFFF" },
    {key: "bahama blue",  value: "#026395" },
    {key: "bahia",  value: "#A5CB0C" },
    {key: "baja white",  value: "#FFF8D1" },
    {key: "bali hai",  value: "#859FAF" },
    {key: "baltic sea",  value: "#2A2630" },
    {key: "bamboo",  value: "#DA6304" },
    {key: "banana mania",  value: "#FBE7B2" },
    {key: "bandicoot",  value: "#858470" },
    {key: "barberry",  value: "#DED717" },
    {key: "barley corn",  value: "#A68B5B" },
    {key: "barley white",  value: "#FFF4CE" },
    {key: "barossa",  value: "#44012D" },
    {key: "bastille",  value: "#292130" },
    {key: "battleship gray",  value: "#828F72" },
    {key: "bay leaf",  value: "#7DA98D" },
    {key: "bay of many",  value: "#273A81" },
    {key: "bazaar",  value: "#98777B" },
    {key: "bean  ",  value: "#3D0C02" },
    {key: "beauty bush",  value: "#EEC1BE" },
    {key: "beaver",  value: "#926F5B" },
    {key: "beeswax",  value: "#FEF2C7" },
    {key: "beige",  value: "#F5F5DC" },
    {key: "bermuda",  value: "#7DD8C6" },
    {key: "bermuda gray",  value: "#6B8BA2" },
    {key: "beryl green",  value: "#DEE5C0" },
    {key: "bianca",  value: "#FCFBF3" },
    {key: "big stone",  value: "#162A40" },
    {key: "bilbao",  value: "#327C14" },
    {key: "biloba flower",  value: "#B2A1EA" },
    {key: "birch",  value: "#373021" },
    {key: "bird flower",  value: "#D4CD16" },
    {key: "biscay",  value: "#1B3162" },
    {key: "bismark",  value: "#497183" },
    {key: "bison hide",  value: "#C1B7A4" },
    {key: "bistre",  value: "#3D2B1F" },
    {key: "bitter",  value: "#868974" },
    {key: "bitter lemon",  value: "#CAE00D" },
    {key: "bittersweet",  value: "#FE6F5E" },
    {key: "bizarre",  value: "#EEDEDA" },
    {key: "black",  value: "#000000" },
    {key: "black bean",  value: "#081910" },
    {key: "black forest",  value: "#0B1304" },
    {key: "black haze",  value: "#F6F7F7" },
    {key: "black marlin",  value: "#3E2C1C" },
    {key: "black olive",  value: "#242E16" },
    {key: "black pearl",  value: "#041322" },
    {key: "black rock",  value: "#0D0332" },
    {key: "black rose",  value: "#67032D" },
    {key: "black russian",  value: "#0A001C" },
    {key: "black squeeze",  value: "#F2FAFA" },
    {key: "black white",  value: "#FFFEF6" },
    {key: "blackberry",  value: "#4D0135" },
    {key: "blackcurrant",  value: "#32293A" },
    {key: "blaze orange",  value: "#FF6600" },
    {key: "bleach white",  value: "#FEF3D8" },
    {key: "bleached cedar",  value: "#2C2133" },
    {key: "blizzard blue",  value: "#A3E3ED" },
    {key: "blossom",  value: "#DCB4BC" },
    {key: "blue",  value: "#0000FF" },
    {key: "blue bayoux",  value: "#496679" },
    {key: "blue bell",  value: "#9999CC" },
    {key: "blue chalk",  value: "#F1E9FF" },
    {key: "blue charcoal",  value: "#010D1A" },
    {key: "blue chill",  value: "#0C8990" },
    {key: "blue diamond",  value: "#380474" },
    {key: "blue dianne",  value: "#204852" },
    {key: "blue gem",  value: "#2C0E8C" },
    {key: "blue haze",  value: "#BFBED8" },
    {key: "blue lagoon",  value: "#017987" },
    {key: "blue marguerite",  value: "#7666C6" },
    {key: "blue ribbon",  value: "#0066FF" },
    {key: "blue romance",  value: "#D2F6DE" },
    {key: "blue smoke",  value: "#748881" },
    {key: "blue stone",  value: "#016162" },
    {key: "blue violet",  value: "#6456B7" },
    {key: "blue whale",  value: "#042E4C" },
    {key: "blue zodiac",  value: "#13264D" },
    {key: "blumine",  value: "#18587A" },
    {key: "blush",  value: "#B44668" },
    {key: "blush pink",  value: "#FF6FFF" },
    {key: "bombay",  value: "#AFB1B8" },
    {key: "bon jour",  value: "#E5E0E1" },
    {key: "bondi blue",  value: "#0095B6" },
    {key: "bone",  value: "#E4D1C0" },
    {key: "bordeaux",  value: "#5C0120" },
    {key: "bossanova",  value: "#4E2A5A" },
    {key: "boston blue",  value: "#3B91B4" },
    {key: "botticelli",  value: "#C7DDE5" },
    {key: "bottle green",  value: "#093624" },
    {key: "boulder",  value: "#7A7A7A" },
    {key: "bouquet",  value: "#AE809E" },
    {key: "bourbon",  value: "#BA6F1E" },
    {key: "bracken",  value: "#4A2A04" },
    {key: "brandy",  value: "#DEC196" },
    {key: "brandy punch",  value: "#CD8429" },
    {key: "brandy rose",  value: "#BB8983" },
    {key: "breaker bay",  value: "#5DA19F" },
    {key: "brick red",  value: "#C62D42" },
    {key: "bridal heath",  value: "#FFFAF4" },
    {key: "bridesmaid",  value: "#FEF0EC" },
    {key: "bright gray",  value: "#3C4151" },
    {key: "bright green",  value: "#66FF00" },
    {key: "bright red",  value: "#B10000" },
    {key: "bright sun",  value: "#FED33C" },
    {key: "bright turquoise",  value: "#08E8DE" },
    {key: "brilliant rose",  value: "#F653A6" },
    {key: "brink pink",  value: "#FB607F" },
    {key: "bronco",  value: "#ABA196" },
    {key: "bronze",  value: "#3F2109" },
    {key: "bronze olive",  value: "#4E420C" },
    {key: "bronzetone",  value: "#4D400F" },
    {key: "broom",  value: "#FFEC13" },
    {key: "brown",  value: "#964B00" },
    {key: "brown bramble",  value: "#592804" },
    {key: "brown derby",  value: "#492615" },
    {key: "brown pod",  value: "#401801" },
    {key: "brown rust",  value: "#AF593E" },
    {key: "brown tumbleweed",  value: "#37290E" },
    {key: "bubbles",  value: "#E7FEFF" },
    {key: "buccaneer",  value: "#622F30" },
    {key: "bud",  value: "#A8AE9C" },
    {key: "buddha gold",  value: "#C1A004" },
    {key: "buff",  value: "#F0DC82" },
    {key: "bulgarian rose",  value: "#480607" },
    {key: "bull shot",  value: "#864D1E" },
    {key: "bunker",  value: "#0D1117" },
    {key: "bunting",  value: "#151F4C" },
    {key: "burgundy",  value: "#900020" },
    {key: "burnham",  value: "#002E20" },
    {key: "burning orange",  value: "#FF7034" },
    {key: "burning sand",  value: "#D99376" },
    {key: "burnt maroon",  value: "#420303" },
    {key: "burnt orange",  value: "#CC5500" },
    {key: "burnt sienna",  value: "#E97451" },
    {key: "burnt umber",  value: "#8A3324" },
    {key: "bush",  value: "#0D2E1C" },
    {key: "buttercup",  value: "#F3AD16" },
    {key: "buttered rum",  value: "#A1750D" },
    {key: "butterfly bush",  value: "#624E9A" },
    {key: "buttermilk",  value: "#FFF1B5" },
    {key: "buttery white",  value: "#FFFCEA" },
    {key: "cab sav",  value: "#4D0A18" },
    {key: "cabaret",  value: "#D94972" },
    {key: "cabbage pont",  value: "#3F4C3A" },
    {key: "cactus",  value: "#587156" },
    {key: "cadet blue",  value: "#A9B2C3" },
    {key: "cadillac",  value: "#B04C6A" },
    {key: "cafe royale",  value: "#6F440C" },
    {key: "calico",  value: "#E0C095" },
    {key: "california",  value: "#FE9D04" },
    {key: "calypso",  value: "#31728D" },
    {key: "camarone",  value: "#00581A" },
    {key: "camelot",  value: "#893456" },
    {key: "cameo",  value: "#D9B99B" },
    {key: "camouflage",  value: "#3C3910" },
    {key: "camouflage green",  value: "#78866B" },
    {key: "can can",  value: "#D591A4" },
    {key: "canary",  value: "#F3FB62" },
    {key: "candlelight",  value: "#FCD917" },
    {key: "candy corn",  value: "#FBEC5D" },
    {key: "cannon black",  value: "#251706" },
    {key: "cannon pink",  value: "#894367" },
    {key: "cape cod",  value: "#3C4443" },
    {key: "cape honey",  value: "#FEE5AC" },
    {key: "cape palliser",  value: "#A26645" },
    {key: "caper",  value: "#DCEDB4" },
    {key: "caramel",  value: "#FFDDAF" },
    {key: "cararra",  value: "#EEEEE8" },
    {key: "cardin green",  value: "#01361C" },
    {key: "cardinal",  value: "#C41E3A" },
    {key: "cardinal pink",  value: "#8C055E" },
    {key: "careys pink",  value: "#D29EAA" },
    {key: "caribbean green",  value: "#00CC99" },
    {key: "carissma",  value: "#EA88A8" },
    {key: "carla",  value: "#F3FFD8" },
    {key: "carmine",  value: "#960018" },
    {key: "carnaby tan",  value: "#5C2E01" },
    {key: "carnation",  value: "#F95A61" },
    {key: "carnation pink",  value: "#FFA6C9" },
    {key: "carousel pink",  value: "#F9E0ED" },
    {key: "carrot orange",  value: "#ED9121" },
    {key: "casablanca",  value: "#F8B853" },
    {key: "casal",  value: "#2F6168" },
    {key: "cascade",  value: "#8BA9A5" },
    {key: "cashmere",  value: "#E6BEA5" },
    {key: "casper",  value: "#ADBED1" },
    {key: "castro",  value: "#52001F" },
    {key: "catalina blue",  value: "#062A78" },
    {key: "catskill white",  value: "#EEF6F7" },
    {key: "cavern pink",  value: "#E3BEBE" },
    {key: "cedar",  value: "#3E1C14" },
    {key: "cedar wood finish",  value: "#711A00" },
    {key: "celadon",  value: "#ACE1AF" },
    {key: "celery",  value: "#B8C25D" },
    {key: "celeste",  value: "#D1D2CA" },
    {key: "cello",  value: "#1E385B" },
    {key: "celtic",  value: "#163222" },
    {key: "cement",  value: "#8D7662" },
    {key: "ceramic",  value: "#FCFFF9" },
    {key: "cerise",  value: "#DA3287" },
    {key: "cerise red",  value: "#DE3163" },
    {key: "cerulean",  value: "#02A4D3" },
    {key: "cerulean blue",  value: "#2A52BE" },
    {key: "chablis",  value: "#FFF4F3" },
    {key: "chalet green",  value: "#516E3D" },
    {key: "chalky",  value: "#EED794" },
    {key: "chambray",  value: "#354E8C" },
    {key: "chamois",  value: "#EDDCB1" },
    {key: "champagne",  value: "#FAECCC" },
    {key: "chantilly",  value: "#F8C3DF" },
    {key: "charade",  value: "#292937" },
    {key: "chardon",  value: "#FFF3F1" },
    {key: "chardonnay",  value: "#FFCD8C" },
    {key: "charlotte",  value: "#BAEEF9" },
    {key: "charm",  value: "#D47494" },
    {key: "chartreuse",  value: "#7FFF00" },
    {key: "chartreuse yellow",  value: "#DFFF00" },
    {key: "chateau green",  value: "#40A860" },
    {key: "chatelle",  value: "#BDB3C7" },
    {key: "chathams blue",  value: "#175579" },
    {key: "chelsea cucumber",  value: "#83AA5D" },
    {key: "chelsea gem",  value: "#9E5302" },
    {key: "chenin",  value: "#DFCD6F" },
    {key: "cherokee",  value: "#FCDA98" },
    {key: "cherry pie",  value: "#2A0359" },
    {key: "cherrywood",  value: "#651A14" },
    {key: "cherub",  value: "#F8D9E9" },
    {key: "chestnut",  value: "#B94E48" },
    {key: "chestnut rose",  value: "#CD5C5C" },
    {key: "chetwode blue",  value: "#8581D9" },
    {key: "chicago",  value: "#5D5C58" },
    {key: "chiffon",  value: "#F1FFC8" },
    {key: "chilean fire",  value: "#F77703" },
    {key: "chilean heath",  value: "#FFFDE6" },
    {key: "china ivory",  value: "#FCFFE7" },
    {key: "chino",  value: "#CEC7A7" },
    {key: "chinook",  value: "#A8E3BD" },
    {key: "chocolate",  value: "#370202" },
    {key: "christalle",  value: "#33036B" },
    {key: "christi",  value: "#67A712" },
    {key: "christine",  value: "#E7730A" },
    {key: "chrome white",  value: "#E8F1D4" },
    {key: "cinder",  value: "#0E0E18" },
    {key: "cinderella",  value: "#FDE1DC" },
    {key: "cinnabar",  value: "#E34234" },
    {key: "cinnamon",  value: "#7B3F00" },
    {key: "cioccolato",  value: "#55280C" },
    {key: "citrine white",  value: "#FAF7D6" },
    {key: "citron",  value: "#9EA91F" },
    {key: "citrus",  value: "#A1C50A" },
    {key: "clairvoyant",  value: "#480656" },
    {key: "clam shell",  value: "#D4B6AF" },
    {key: "claret",  value: "#7F1734" },
    {key: "classic rose",  value: "#FBCCE7" },
    {key: "clay ash",  value: "#BDC8B3" },
    {key: "clay creek",  value: "#8A8360" },
    {key: "clear day",  value: "#E9FFFD" },
    {key: "clementine",  value: "#E96E00" },
    {key: "clinker",  value: "#371D09" },
    {key: "cloud",  value: "#C7C4BF" },
    {key: "cloud burst",  value: "#202E54" },
    {key: "cloudy",  value: "#ACA59F" },
    {key: "clover",  value: "#384910" },
    {key: "cobalt",  value: "#0047AB" },
    {key: "cocoa bean",  value: "#481C1C" },
    {key: "cocoa brown",  value: "#301F1E" },
    {key: "coconut cream",  value: "#F8F7DC" },
    {key: "cod gray",  value: "#0B0B0B" },
    {key: "coffee",  value: "#706555" },
    {key: "coffee bean",  value: "#2A140E" },
    {key: "cognac",  value: "#9F381D" },
    {key: "cola",  value: "#3F2500" },
    {key: "cold purple",  value: "#ABA0D9" },
    {key: "cold turkey",  value: "#CEBABA" },
    {key: "colonial white",  value: "#FFEDBC" },
    {key: "comet",  value: "#5C5D75" },
    {key: "como",  value: "#517C66" },
    {key: "conch",  value: "#C9D9D2" },
    {key: "concord",  value: "#7C7B7A" },
    {key: "concrete",  value: "#F2F2F2" },
    {key: "confetti",  value: "#E9D75A" },
    {key: "congo brown",  value: "#593737" },
    {key: "congress blue",  value: "#02478E" },
    {key: "conifer",  value: "#ACDD4D" },
    {key: "contessa",  value: "#C6726B" },
    {key: "copper",  value: "#B87333" },
    {key: "copper canyon",  value: "#7E3A15" },
    {key: "copper rose",  value: "#996666" },
    {key: "copper rust",  value: "#944747" },
    {key: "copperfield",  value: "#DA8A67" },
    {key: "coral",  value: "#FF7F50" },
    {key: "coral red",  value: "#FF4040" },
    {key: "coral reef",  value: "#C7BCA2" },
    {key: "coral tree",  value: "#A86B6B" },
    {key: "corduroy",  value: "#606E68" },
    {key: "coriander",  value: "#C4D0B0" },
    {key: "cork",  value: "#40291D" },
    {key: "corn",  value: "#E7BF05" },
    {key: "corn field",  value: "#F8FACD" },
    {key: "corn harvest",  value: "#8B6B0B" },
    {key: "cornflower",  value: "#93CCEA" },
    {key: "cornflower blue",  value: "#6495ED" },
    {key: "cornflower lilac",  value: "#FFB0AC" },
    {key: "corvette",  value: "#FAD3A2" },
    {key: "cosmic",  value: "#76395D" },
    {key: "cosmos",  value: "#FFD8D9" },
    {key: "costa del sol",  value: "#615D30" },
    {key: "cotton candy",  value: "#FFB7D5" },
    {key: "cotton seed",  value: "#C2BDB6" },
    {key: "county green",  value: "#01371A" },
    {key: "cowboy",  value: "#4D282D" },
    {key: "crail",  value: "#B95140" },
    {key: "cranberry",  value: "#DB5079" },
    {key: "crater brown",  value: "#462425" },
    {key: "cream",  value: "#FFFDD0" },
    {key: "cream brulee",  value: "#FFE5A0" },
    {key: "cream can",  value: "#F5C85C" },
    {key: "creole",  value: "#1E0F04" },
    {key: "crete",  value: "#737829" },
    {key: "crimson",  value: "#DC143C" },
    {key: "crocodile",  value: "#736D58" },
    {key: "crown of thorns",  value: "#771F1F" },
    {key: "crowshead",  value: "#1C1208" },
    {key: "cruise",  value: "#B5ECDF" },
    {key: "crusoe",  value: "#004816" },
    {key: "crusta",  value: "#FD7B33" },
    {key: "cumin",  value: "#924321" },
    {key: "cumulus",  value: "#FDFFD5" },
    {key: "cupid",  value: "#FBBEDA" },
    {key: "curious blue",  value: "#2596D1" },
    {key: "cutty sark",  value: "#507672" },
    {key: "cyan / aqua",  value: "#00FFFF" },
    {key: "cyprus",  value: "#003E40" },
    {key: "daintree",  value: "#012731" },
    {key: "dairy cream",  value: "#F9E4BC" },
    {key: "daisy bush",  value: "#4F2398" },
    {key: "dallas",  value: "#6E4B26" },
    {key: "dandelion",  value: "#FED85D" },
    {key: "danube",  value: "#6093D1" },
    {key: "dark blue",  value: "#0000C8" },
    {key: "dark burgundy",  value: "#770F05" },
    {key: "dark ebony",  value: "#3C2005" },
    {key: "dark fern",  value: "#0A480D" },
    {key: "dark tan",  value: "#661010" },
    {key: "dawn",  value: "#A6A29A" },
    {key: "dawn pink",  value: "#F3E9E5" },
    {key: "de york",  value: "#7AC488" },
    {key: "deco",  value: "#D2DA97" },
    {key: "deep blue",  value: "#220878" },
    {key: "deep blush",  value: "#E47698" },
    {key: "deep bronze",  value: "#4A3004" },
    {key: "deep cerulean",  value: "#007BA7" },
    {key: "deep cove",  value: "#051040" },
    {key: "deep fir",  value: "#002900" },
    {key: "deep forest green",  value: "#182D09" },
    {key: "deep koamaru",  value: "#1B127B" },
    {key: "deep oak",  value: "#412010" },
    {key: "deep sapphire",  value: "#082567" },
    {key: "deep sea",  value: "#01826B" },
    {key: "deep sea green",  value: "#095859" },
    {key: "deep teal",  value: "#003532" },
    {key: "del rio",  value: "#B09A95" },
    {key: "dell",  value: "#396413" },
    {key: "delta",  value: "#A4A49D" },
    {key: "deluge",  value: "#7563A8" },
    {key: "denim",  value: "#1560BD" },
    {key: "derby",  value: "#FFEED8" },
    {key: "desert",  value: "#AE6020" },
    {key: "desert sand",  value: "#EDC9AF" },
    {key: "desert storm",  value: "#F8F8F7" },
    {key: "dew",  value: "#EAFFFE" },
    {key: "di serria",  value: "#DB995E" },
    {key: "diesel",  value: "#130000" },
    {key: "dingley",  value: "#5D7747" },
    {key: "disco",  value: "#871550" },
    {key: "dixie",  value: "#E29418" },
    {key: "dodger blue",  value: "#1E90FF" },
    {key: "dolly",  value: "#F9FF8B" },
    {key: "dolphin",  value: "#646077" },
    {key: "domino",  value: "#8E775E" },
    {key: "don juan",  value: "#5D4C51" },
    {key: "donkey brown",  value: "#A69279" },
    {key: "dorado",  value: "#6B5755" },
    {key: "double colonial white",  value: "#EEE3AD" },
    {key: "double pearl lusta",  value: "#FCF4D0" },
    {key: "double spanish white",  value: "#E6D7B9" },
    {key: "dove gray",  value: "#6D6C6C" },
    {key: "downriver",  value: "#092256" },
    {key: "downy",  value: "#6FD0C5" },
    {key: "driftwood",  value: "#AF8751" },
    {key: "drover",  value: "#FDF7AD" },
    {key: "dull lavender",  value: "#A899E6" },
    {key: "dune",  value: "#383533" },
    {key: "dust storm",  value: "#E5CCC9" },
    {key: "dusty gray",  value: "#A8989B" },
    {key: "eagle",  value: "#B6BAA4" },
    {key: "earls green",  value: "#C9B93B" },
    {key: "early dawn",  value: "#FFF9E6" },
    {key: "east bay",  value: "#414C7D" },
    {key: "east side",  value: "#AC91CE" },
    {key: "eastern blue",  value: "#1E9AB0" },
    {key: "ebb",  value: "#E9E3E3" },
    {key: "ebony",  value: "#0C0B1D" },
    {key: "ebony clay",  value: "#26283B" },
    {key: "eclipse",  value: "#311C17" },
    {key: "ecru white",  value: "#F5F3E5" },
    {key: "ecstasy",  value: "#FA7814" },
    {key: "eden",  value: "#105852" },
    {key: "edgewater",  value: "#C8E3D7" },
    {key: "edward",  value: "#A2AEAB" },
    {key: "egg sour",  value: "#FFF4DD" },
    {key: "egg white",  value: "#FFEFC1" },
    {key: "eggplant",  value: "#614051" },
    {key: "el paso",  value: "#1E1708" },
    {key: "el salva",  value: "#8F3E33" },
    {key: "electric lime",  value: "#CCFF00" },
    {key: "electric violet",  value: "#8B00FF" },
    {key: "elephant",  value: "#123447" },
    {key: "elf green",  value: "#088370" },
    {key: "elm",  value: "#1C7C7D" },
    {key: "emerald",  value: "#50C878" },
    {key: "eminence",  value: "#6C3082" },
    {key: "emperor",  value: "#514649" },
    {key: "empress",  value: "#817377" },
    {key: "endeavour",  value: "#0056A7" },
    {key: "energy yellow",  value: "#F8DD5C" },
    {key: "english holly",  value: "#022D15" },
    {key: "english walnut",  value: "#3E2B23" },
    {key: "envy",  value: "#8BA690" },
    {key: "equator",  value: "#E1BC64" },
    {key: "espresso",  value: "#612718" },
    {key: "eternity",  value: "#211A0E" },
    {key: "eucalyptus",  value: "#278A5B" },
    {key: "eunry",  value: "#CFA39D" },
    {key: "evening sea",  value: "#024E46" },
    {key: "everglade",  value: "#1C402E" },
    {key: "faded jade",  value: "#427977" },
    {key: "fair pink",  value: "#FFEFEC" },
    {key: "falcon",  value: "#7F626D" },
    {key: "fall green",  value: "#ECEBBD" },
    {key: "falu red",  value: "#801818" },
    {key: "fantasy",  value: "#FAF3F0" },
    {key: "fedora",  value: "#796A78" },
    {key: "feijoa",  value: "#9FDD8C" },
    {key: "fern",  value: "#63B76C" },
    {key: "fern frond",  value: "#657220" },
    {key: "fern green",  value: "#4F7942" },
    {key: "ferra",  value: "#704F50" },
    {key: "festival",  value: "#FBE96C" },
    {key: "feta",  value: "#F0FCEA" },
    {key: "fiery orange",  value: "#B35213" },
    {key: "finch",  value: "#626649" },
    {key: "finlandia",  value: "#556D56" },
    {key: "finn",  value: "#692D54" },
    {key: "fiord",  value: "#405169" },
    {key: "fire",  value: "#AA4203" },
    {key: "fire bush",  value: "#E89928" },
    {key: "firefly",  value: "#0E2A30" },
    {key: "flame pea",  value: "#DA5B38" },
    {key: "flamenco",  value: "#FF7D07" },
    {key: "flamingo",  value: "#F2552A" },
    {key: "flax",  value: "#EEDC82" },
    {key: "flax smoke",  value: "#7B8265" },
    {key: "flesh",  value: "#FFCBA4" },
    {key: "flint",  value: "#6F6A61" },
    {key: "flirt",  value: "#A2006D" },
    {key: "flush mahogany",  value: "#CA3435" },
    {key: "flush orange",  value: "#FF7F00" },
    {key: "foam",  value: "#D8FCFA" },
    {key: "fog",  value: "#D7D0FF" },
    {key: "foggy gray",  value: "#CBCAB6" },
    {key: "forest green",  value: "#228B22" },
    {key: "forget me not",  value: "#FFF1EE" },
    {key: "fountain blue",  value: "#56B4BE" },
    {key: "frangipani",  value: "#FFDEB3" },
    {key: "french gray",  value: "#BDBDC6" },
    {key: "french lilac",  value: "#ECC7EE" },
    {key: "french pass",  value: "#BDEDFD" },
    {key: "french rose",  value: "#F64A8A" },
    {key: "fresh eggplant",  value: "#990066" },
    {key: "friar gray",  value: "#807E79" },
    {key: "fringy flower",  value: "#B1E2C1" },
    {key: "froly",  value: "#F57584" },
    {key: "frost",  value: "#EDF5DD" },
    {key: "frosted mint",  value: "#DBFFF8" },
    {key: "frostee",  value: "#E4F6E7" },
    {key: "fruit salad",  value: "#4F9D5D" },
    {key: "fuchsia blue",  value: "#7A58C1" },
    {key: "fuchsia pink",  value: "#C154C1" },
    {key: "fuego",  value: "#BEDE0D" },
    {key: "fuel yellow",  value: "#ECA927" },
    {key: "fun blue",  value: "#1959A8" },
    {key: "fun green",  value: "#016D39" },
    {key: "fuscous gray",  value: "#54534D" },
    {key: "fuzzy wuzzy brown",  value: "#C45655" },
    {key: "gable green",  value: "#163531" },
    {key: "gallery",  value: "#EFEFEF" },
    {key: "galliano",  value: "#DCB20C" },
    {key: "gamboge",  value: "#E49B0F" },
    {key: "geebung",  value: "#D18F1B" },
    {key: "genoa",  value: "#15736B" },
    {key: "geraldine",  value: "#FB8989" },
    {key: "geyser",  value: "#D4DFE2" },
    {key: "ghost",  value: "#C7C9D5" },
    {key: "gigas",  value: "#523C94" },
    {key: "gimblet",  value: "#B8B56A" },
    {key: "gin",  value: "#E8F2EB" },
    {key: "gin fizz",  value: "#FFF9E2" },
    {key: "givry",  value: "#F8E4BF" },
    {key: "glacier",  value: "#80B3C4" },
    {key: "glade green",  value: "#61845F" },
    {key: "go ben",  value: "#726D4E" },
    {key: "goblin",  value: "#3D7D52" },
    {key: "gold",  value: "#FFD700" },
    {key: "gold drop",  value: "#F18200" },
    {key: "gold sand",  value: "#E6BE8A" },
    {key: "gold tips",  value: "#DEBA13" },
    {key: "golden bell",  value: "#E28913" },
    {key: "golden dream",  value: "#F0D52D" },
    {key: "golden fizz",  value: "#F5FB3D" },
    {key: "golden glow",  value: "#FDE295" },
    {key: "golden grass",  value: "#DAA520" },
    {key: "golden sand",  value: "#F0DB7D" },
    {key: "golden tainoi",  value: "#FFCC5C" },
    {key: "goldenrod",  value: "#FCD667" },
    {key: "gondola",  value: "#261414" },
    {key: "gordons green",  value: "#0B1107" },
    {key: "gorse",  value: "#FFF14F" },
    {key: "gossamer",  value: "#069B81" },
    {key: "gossip",  value: "#D2F8B0" },
    {key: "gothic",  value: "#6D92A1" },
    {key: "governor bay",  value: "#2F3CB3" },
    {key: "grain brown",  value: "#E4D5B7" },
    {key: "grandis",  value: "#FFD38C" },
    {key: "granite green",  value: "#8D8974" },
    {key: "granny apple",  value: "#D5F6E3" },
    {key: "granny smith",  value: "#84A0A0" },
    {key: "granny smith apple",  value: "#9DE093" },
    {key: "grape",  value: "#381A51" },
    {key: "graphite",  value: "#251607" },
    {key: "gravel",  value: "#4A444B" },
    {key: "gray",  value: "#808080" },
    {key: "gray asparagus",  value: "#465945" },
    {key: "gray chateau",  value: "#A2AAB3" },
    {key: "gray nickel",  value: "#C3C3BD" },
    {key: "gray nurse",  value: "#E7ECE6" },
    {key: "gray olive",  value: "#A9A491" },
    {key: "gray suit",  value: "#C1BECD" },
    {key: "green",  value: "#00FF00" },
    {key: "green haze",  value: "#01A368" },
    {key: "green house",  value: "#24500F" },
    {key: "green kelp",  value: "#25311C" },
    {key: "green leaf",  value: "#436A0D" },
    {key: "green mist",  value: "#CBD3B0" },
    {key: "green pea",  value: "#1D6142" },
    {key: "green smoke",  value: "#A4AF6E" },
    {key: "green spring",  value: "#B8C1B1" },
    {key: "green vogue",  value: "#032B52" },
    {key: "green waterloo",  value: "#101405" },
    {key: "green white",  value: "#E8EBE0" },
    {key: "green yellow",  value: "#ADFF2F" },
    {key: "grenadier",  value: "#D54600" },
    {key: "guardsman red",  value: "#BA0101" },
    {key: "gulf blue",  value: "#051657" },
    {key: "gulf stream",  value: "#80B3AE" },
    {key: "gull gray",  value: "#9DACB7" },
    {key: "gum leaf",  value: "#B6D3BF" },
    {key: "gumbo",  value: "#7CA1A6" },
    {key: "gun powder",  value: "#414257" },
    {key: "gunsmoke",  value: "#828685" },
    {key: "gurkha",  value: "#9A9577" },
    {key: "hacienda",  value: "#98811B" },
    {key: "hairy heath",  value: "#6B2A14" },
    {key: "haiti",  value: "#1B1035" },
    {key: "half and half",  value: "#FFFEE1" },
    {key: "half baked",  value: "#85C4CC" },
    {key: "half colonial white",  value: "#FDF6D3" },
    {key: "half dutch white",  value: "#FEF7DE" },
    {key: "half spanish white",  value: "#FEF4DB" },
    {key: "hampton",  value: "#E5D8AF" },
    {key: "harlequin",  value: "#3FFF00" },
    {key: "harp",  value: "#E6F2EA" },
    {key: "harvest gold",  value: "#E0B974" },
    {key: "havelock blue",  value: "#5590D9" },
    {key: "hawaiian tan",  value: "#9D5616" },
    {key: "hawkes blue",  value: "#D4E2FC" },
    {key: "heath",  value: "#541012" },
    {key: "heather",  value: "#B7C3D0" },
    {key: "heathered gray",  value: "#B6B095" },
    {key: "heavy metal",  value: "#2B3228" },
    {key: "heliotrope",  value: "#DF73FF" },
    {key: "hemlock",  value: "#5E5D3B" },
    {key: "hemp",  value: "#907874" },
    {key: "hibiscus",  value: "#B6316C" },
    {key: "highland",  value: "#6F8E63" },
    {key: "hillary",  value: "#ACA586" },
    {key: "himalaya",  value: "#6A5D1B" },
    {key: "hint of green",  value: "#E6FFE9" },
    {key: "hint of red",  value: "#FBF9F9" },
    {key: "hint of yellow",  value: "#FAFDE4" },
    {key: "hippie blue",  value: "#589AAF" },
    {key: "hippie green",  value: "#53824B" },
    {key: "hippie pink",  value: "#AE4560" },
    {key: "hit gray",  value: "#A1ADB5" },
    {key: "hit pink",  value: "#FFAB81" },
    {key: "hokey pokey",  value: "#C8A528" },
    {key: "hoki",  value: "#65869F" },
    {key: "holly",  value: "#011D13" },
    {key: "hollywood cerise",  value: "#F400A1" },
    {key: "honey flower",  value: "#4F1C70" },
    {key: "honeysuckle",  value: "#EDFC84" },
    {key: "hopbush",  value: "#D06DA1" },
    {key: "horizon",  value: "#5A87A0" },
    {key: "horses neck",  value: "#604913" },
    {key: "hot cinnamon",  value: "#D2691E" },
    {key: "hot pink",  value: "#FF69B4" },
    {key: "hot toddy",  value: "#B38007" },
    {key: "humming bird",  value: "#CFF9F3" },
    {key: "hunter green",  value: "#161D10" },
    {key: "hurricane",  value: "#877C7B" },
    {key: "husk",  value: "#B7A458" },
    {key: "ice cold",  value: "#B1F4E7" },
    {key: "iceberg",  value: "#DAF4F0" },
    {key: "illusion",  value: "#F6A4C9" },
    {key: "inch worm",  value: "#B0E313" },
    {key: "indian khaki",  value: "#C3B091" },
    {key: "indian tan",  value: "#4D1E01" },
    {key: "indigo",  value: "#4F69C6" },
    {key: "indochine",  value: "#C26B03" },
    {key: "international klein blue",  value: "#002FA7" },
    {key: "international orange",  value: "#FF4F00" },
    {key: "irish coffee",  value: "#5F3D26" },
    {key: "iroko",  value: "#433120" },
    {key: "iron",  value: "#D4D7D9" },
    {key: "ironside gray",  value: "#676662" },
    {key: "ironstone",  value: "#86483C" },
    {key: "island spice",  value: "#FFFCEE" },
    {key: "ivory",  value: "#FFFFF0" },
    {key: "jacaranda",  value: "#2E0329" },
    {key: "jacarta",  value: "#3A2A6A" },
    {key: "jacko bean",  value: "#2E1905" },
    {key: "jacksons purple",  value: "#20208D" },
    {key: "jade",  value: "#00A86B" },
    {key: "jaffa",  value: "#EF863F" },
    {key: "jagged ice",  value: "#C2E8E5" },
    {key: "jagger",  value: "#350E57" },
    {key: "jaguar",  value: "#080110" },
    {key: "jambalaya",  value: "#5B3013" },
    {key: "janna",  value: "#F4EBD3" },
    {key: "japanese laurel",  value: "#0A6906" },
    {key: "japanese maple",  value: "#780109" },
    {key: "japonica",  value: "#D87C63" },
    {key: "java",  value: "#1FC2C2" },
    {key: "jazzberry jam",  value: "#A50B5E" },
    {key: "jelly bean",  value: "#297B9A" },
    {key: "jet stream",  value: "#B5D2CE" },
    {key: "jewel",  value: "#126B40" },
    {key: "jon",  value: "#3B1F1F" },
    {key: "jonquil",  value: "#EEFF9A" },
    {key: "jordy blue",  value: "#8AB9F1" },
    {key: "judge gray",  value: "#544333" },
    {key: "jumbo",  value: "#7C7B82" },
    {key: "jungle green",  value: "#29AB87" },
    {key: "jungle mist",  value: "#B4CFD3" },
    {key: "juniper",  value: "#6D9292" },
    {key: "just right",  value: "#ECCDB9" },
    {key: "kabul",  value: "#5E483E" },
    {key: "kaitoke green",  value: "#004620" },
    {key: "kangaroo",  value: "#C6C8BD" },
    {key: "karaka",  value: "#1E1609" },
    {key: "karry",  value: "#FFEAD4" },
    {key: "kashmir blue",  value: "#507096" },
    {key: "kelp",  value: "#454936" },
    {key: "kenyan copper",  value: "#7C1C05" },
    {key: "keppel",  value: "#3AB09E" },
    {key: "key lime pie",  value: "#BFC921" },
    {key: "khaki",  value: "#F0E68C" },
    {key: "kidnapper",  value: "#E1EAD4" },
    {key: "kilamanjaro",  value: "#240C02" },
    {key: "killarney",  value: "#3A6A47" },
    {key: "kimberly",  value: "#736C9F" },
    {key: "kingfisher daisy",  value: "#3E0480" },
    {key: "kobi",  value: "#E79FC4" },
    {key: "kokoda",  value: "#6E6D57" },
    {key: "korma",  value: "#8F4B0E" },
    {key: "koromiko",  value: "#FFBD5F" },
    {key: "kournikova",  value: "#FFE772" },
    {key: "kumera",  value: "#886221" },
    {key: "la palma",  value: "#368716" },
    {key: "la rioja",  value: "#B3C110" },
    {key: "las palmas",  value: "#C6E610" },
    {key: "laser",  value: "#C8B568" },
    {key: "laser lemon",  value: "#FFFF66" },
    {key: "laurel",  value: "#749378" },
    {key: "lavender",  value: "#B57EDC" },
    {key: "lavender blush",  value: "#FFF0F5" },
    {key: "lavender gray",  value: "#BDBBD7" },
    {key: "lavender magenta",  value: "#EE82EE" },
    {key: "lavender pink",  value: "#FBAED2" },
    {key: "lavender purple",  value: "#967BB6" },
    {key: "lavender rose",  value: "#FBA0E3" },
    {key: "leather",  value: "#967059" },
    {key: "lemon",  value: "#FDE910" },
    {key: "lemon chiffon",  value: "#FFFACD" },
    {key: "lemon ginger",  value: "#AC9E22" },
    {key: "lemon grass",  value: "#9B9E8F" },
    {key: "light apricot",  value: "#FDD5B1" },
    {key: "light orchid",  value: "#E29CD2" },
    {key: "light wisteria",  value: "#C9A0DC" },
    {key: "lightning yellow",  value: "#FCC01E" },
    {key: "lilac",  value: "#C8A2C8" },
    {key: "lilac bush",  value: "#9874D3" },
    {key: "lily",  value: "#C8AABF" },
    {key: "lily white",  value: "#E7F8FF" },
    {key: "lima",  value: "#76BD17" },
    {key: "lime",  value: "#BFFF00" },
    {key: "limeade",  value: "#6F9D02" },
    {key: "limed ash",  value: "#747D63" },
    {key: "limed oak",  value: "#AC8A56" },
    {key: "limed spruce",  value: "#394851" },
    {key: "linen",  value: "#FAF0E6" },
    {key: "link water",  value: "#D9E4F5" },
    {key: "lipstick",  value: "#AB0563" },
    {key: "lisbon brown",  value: "#423921" },
    {key: "livid brown",  value: "#4D282E" },
    {key: "loafer",  value: "#EEF4DE" },
    {key: "loblolly",  value: "#BDC9CE" },
    {key: "lochinvar",  value: "#2C8C84" },
    {key: "lochmara",  value: "#007EC7" },
    {key: "locust",  value: "#A8AF8E" },
    {key: "log cabin",  value: "#242A1D" },
    {key: "logan",  value: "#AAA9CD" },
    {key: "lola",  value: "#DFCFDB" },
    {key: "london hue",  value: "#BEA6C3" },
    {key: "lonestar",  value: "#6D0101" },
    {key: "lotus",  value: "#863C3C" },
    {key: "loulou",  value: "#460B41" },
    {key: "lucky",  value: "#AF9F1C" },
    {key: "lucky point",  value: "#1A1A68" },
    {key: "lunar green",  value: "#3C493A" },
    {key: "luxor gold",  value: "#A7882C" },
    {key: "lynch",  value: "#697E9A" },
    {key: "mabel",  value: "#D9F7FF" },
    {key: "macaroni and cheese",  value: "#FFB97B" },
    {key: "madang",  value: "#B7F0BE" },
    {key: "madison",  value: "#09255D" },
    {key: "madras",  value: "#3F3002" },
    {key: "magenta / fuchsia",  value: "#FF00FF" },
    {key: "magic mint",  value: "#AAF0D1" },
    {key: "magnolia",  value: "#F8F4FF" },
    {key: "mahogany",  value: "#4E0606" },
    {key: "mai tai",  value: "#B06608" },
    {key: "maize",  value: "#F5D5A0" },
    {key: "makara",  value: "#897D6D" },
    {key: "mako",  value: "#444954" },
    {key: "malachite",  value: "#0BDA51" },
    {key: "malibu",  value: "#7DC8F7" },
    {key: "mallard",  value: "#233418" },
    {key: "malta",  value: "#BDB2A1" },
    {key: "mamba",  value: "#8E8190" },
    {key: "manatee",  value: "#8D90A1" },
    {key: "mandalay",  value: "#AD781B" },
    {key: "mandy",  value: "#E25465" },
    {key: "mandys pink",  value: "#F2C3B2" },
    {key: "mango tango",  value: "#E77200" },
    {key: "manhattan",  value: "#F5C999" },
    {key: "mantis",  value: "#74C365" },
    {key: "mantle",  value: "#8B9C90" },
    {key: "manz",  value: "#EEEF78" },
    {key: "mardi gras",  value: "#350036" },
    {key: "marigold",  value: "#B98D28" },
    {key: "marigold yellow",  value: "#FBE870" },
    {key: "mariner",  value: "#286ACD" },
    {key: "maroon",  value: "#800000" },
    {key: "maroon flush",  value: "#C32148" },
    {key: "maroon oak",  value: "#520C17" },
    {key: "marshland",  value: "#0B0F08" },
    {key: "martini",  value: "#AFA09E" },
    {key: "martinique",  value: "#363050" },
    {key: "marzipan",  value: "#F8DB9D" },
    {key: "masala",  value: "#403B38" },
    {key: "matisse",  value: "#1B659D" },
    {key: "matrix",  value: "#B05D54" },
    {key: "matterhorn",  value: "#4E3B41" },
    {key: "mauve",  value: "#E0B0FF" },
    {key: "mauvelous",  value: "#F091A9" },
    {key: "maverick",  value: "#D8C2D5" },
    {key: "medium carmine",  value: "#AF4035" },
    {key: "medium purple",  value: "#9370DB" },
    {key: "medium red violet",  value: "#BB3385" },
    {key: "melanie",  value: "#E4C2D5" },
    {key: "melanzane",  value: "#300529" },
    {key: "melon",  value: "#FEBAAD" },
    {key: "melrose",  value: "#C7C1FF" },
    {key: "mercury",  value: "#E5E5E5" },
    {key: "merino",  value: "#F6F0E6" },
    {key: "merlin",  value: "#413C37" },
    {key: "merlot",  value: "#831923" },
    {key: "metallic bronze",  value: "#49371B" },
    {key: "metallic copper",  value: "#71291D" },
    {key: "meteor",  value: "#D07D12" },
    {key: "meteorite",  value: "#3C1F76" },
    {key: "mexican red",  value: "#A72525" },
    {key: "mid gray",  value: "#5F5F6E" },
    {key: "midnight",  value: "#011635" },
    {key: "midnight blue",  value: "#003366" },
    {key: "midnight moss",  value: "#041004" },
    {key: "mikado",  value: "#2D2510" },
    {key: "milan",  value: "#FAFFA4" },
    {key: "milano red",  value: "#B81104" },
    {key: "milk punch",  value: "#FFF6D4" },
    {key: "millbrook",  value: "#594433" },
    {key: "mimosa",  value: "#F8FDD3" },
    {key: "mindaro",  value: "#E3F988" },
    {key: "mine shaft",  value: "#323232" },
    {key: "mineral green",  value: "#3F5D53" },
    {key: "ming",  value: "#36747D" },
    {key: "minsk",  value: "#3F307F" },
    {key: "mint green",  value: "#98FF98" },
    {key: "mint julep",  value: "#F1EEC1" },
    {key: "mint tulip",  value: "#C4F4EB" },
    {key: "mirage",  value: "#161928" },
    {key: "mischka",  value: "#D1D2DD" },
    {key: "mist gray",  value: "#C4C4BC" },
    {key: "mobster",  value: "#7F7589" },
    {key: "moccaccino",  value: "#6E1D14" },
    {key: "mocha",  value: "#782D19" },
    {key: "mojo",  value: "#C04737" },
    {key: "mona lisa",  value: "#FFA194" },
    {key: "monarch",  value: "#8B0723" },
    {key: "mondo",  value: "#4A3C30" },
    {key: "mongoose",  value: "#B5A27F" },
    {key: "monsoon",  value: "#8A8389" },
    {key: "monte carlo",  value: "#83D0C6" },
    {key: "monza",  value: "#C7031E" },
    {key: "moody blue",  value: "#7F76D3" },
    {key: "moon glow",  value: "#FCFEDA" },
    {key: "moon mist",  value: "#DCDDCC" },
    {key: "moon raker",  value: "#D6CEF6" },
    {key: "morning glory",  value: "#9EDEE0" },
    {key: "morocco brown",  value: "#441D00" },
    {key: "mortar",  value: "#504351" },
    {key: "mosque",  value: "#036A6E" },
    {key: "moss green",  value: "#ADDFAD" },
    {key: "mountain meadow",  value: "#1AB385" },
    {key: "mountain mist",  value: "#959396" },
    {key: "mountbatten pink",  value: "#997A8D" },
    {key: "muddy waters",  value: "#B78E5C" },
    {key: "muesli",  value: "#AA8B5B" },
    {key: "mulberry",  value: "#C54B8C" },
    {key: "mulberry wood",  value: "#5C0536" },
    {key: "mule fawn",  value: "#8C472F" },
    {key: "mulled wine",  value: "#4E4562" },
    {key: "mustard",  value: "#FFDB58" },
    {key: "my pink",  value: "#D69188" },
    {key: "my sin",  value: "#FFB31F" },
    {key: "mystic",  value: "#E2EBED" },
    {key: "nandor",  value: "#4B5D52" },
    {key: "napa",  value: "#ACA494" },
    {key: "narvik",  value: "#EDF9F1" },
    {key: "natural gray",  value: "#8B8680" },
    {key: "navajo white",  value: "#FFDEAD" },
    {key: "navy blue",  value: "#000080" },
    {key: "nebula",  value: "#CBDBD6" },
    {key: "negroni",  value: "#FFE2C5" },
    {key: "neon carrot",  value: "#FF9933" },
    {key: "nepal",  value: "#8EABC1" },
    {key: "neptune",  value: "#7CB7BB" },
    {key: "nero",  value: "#140600" },
    {key: "nevada",  value: "#646E75" },
    {key: "new orleans",  value: "#F3D69D" },
    {key: "new york pink",  value: "#D7837F" },
    {key: "niagara",  value: "#06A189" },
    {key: "night rider",  value: "#1F120F" },
    {key: "night shadz",  value: "#AA375A" },
    {key: "nile blue",  value: "#193751" },
    {key: "nobel",  value: "#B7B1B1" },
    {key: "nomad",  value: "#BAB1A2" },
    {key: "norway",  value: "#A8BD9F" },
    {key: "nugget",  value: "#C59922" },
    {key: "nutmeg",  value: "#81422C" },
    {key: "nutmeg wood finish",  value: "#683600" },
    {key: "oasis",  value: "#FEEFCE" },
    {key: "observatory",  value: "#02866F" },
    {key: "ocean green",  value: "#41AA78" },
    {key: "ochre",  value: "#CC7722" },
    {key: "off green",  value: "#E6F8F3" },
    {key: "off yellow",  value: "#FEF9E3" },
    {key: "oil",  value: "#281E15" },
    {key: "old brick",  value: "#901E1E" },
    {key: "old copper",  value: "#724A2F" },
    {key: "old gold",  value: "#CFB53B" },
    {key: "old lace",  value: "#FDF5E6" },
    {key: "old lavender",  value: "#796878" },
    {key: "old rose",  value: "#C08081" },
    {key: "olive",  value: "#808000" },
    {key: "olive drab",  value: "#6B8E23" },
    {key: "olive green",  value: "#B5B35C" },
    {key: "olive haze",  value: "#8B8470" },
    {key: "olivetone",  value: "#716E10" },
    {key: "olivine",  value: "#9AB973" },
    {key: "onahau",  value: "#CDF4FF" },
    {key: "onion",  value: "#2F270E" },
    {key: "opal",  value: "#A9C6C2" },
    {key: "opium",  value: "#8E6F70" },
    {key: "oracle",  value: "#377475" },
    {key: "orange",  value: "#FF681F" },
    {key: "orange peel",  value: "#FFA000" },
    {key: "orange roughy",  value: "#C45719" },
    {key: "orange white",  value: "#FEFCED" },
    {key: "orchid",  value: "#DA70D6" },
    {key: "orchid white",  value: "#FFFDF3" },
    {key: "oregon",  value: "#9B4703" },
    {key: "orient",  value: "#015E85" },
    {key: "oriental pink",  value: "#C69191" },
    {key: "orinoco",  value: "#F3FBD4" },
    {key: "oslo gray",  value: "#878D91" },
    {key: "ottoman",  value: "#E9F8ED" },
    {key: "outer space",  value: "#2D383A" },
    {key: "outrageous orange",  value: "#FF6037" },
    {key: "oxford blue",  value: "#384555" },
    {key: "oxley",  value: "#779E86" },
    {key: "oyster bay",  value: "#DAFAFF" },
    {key: "oyster pink",  value: "#E9CECD" },
    {key: "paarl",  value: "#A65529" },
    {key: "pablo",  value: "#776F61" },
    {key: "pacific blue",  value: "#009DC4" },
    {key: "pacifika",  value: "#778120" },
    {key: "paco",  value: "#411F10" },
    {key: "padua",  value: "#ADE6C4" },
    {key: "pale canary",  value: "#FFFF99" },
    {key: "pale leaf",  value: "#C0D3B9" },
    {key: "pale oyster",  value: "#988D77" },
    {key: "pale prim",  value: "#FDFEB8" },
    {key: "pale rose",  value: "#FFE1F2" },
    {key: "pale sky",  value: "#6E7783" },
    {key: "pale slate",  value: "#C3BFC1" },
    {key: "palm green",  value: "#09230F" },
    {key: "palm leaf",  value: "#19330E" },
    {key: "pampas",  value: "#F4F2EE" },
    {key: "panache",  value: "#EAF6EE" },
    {key: "pancho",  value: "#EDCDAB" },
    {key: "papaya whip",  value: "#FFEFD5" },
    {key: "paprika",  value: "#8D0226" },
    {key: "paradiso",  value: "#317D82" },
    {key: "parchment",  value: "#F1E9D2" },
    {key: "paris daisy",  value: "#FFF46E" },
    {key: "paris m",  value: "#26056A" },
    {key: "paris white",  value: "#CADCD4" },
    {key: "parsley",  value: "#134F19" },
    {key: "pastel green",  value: "#77DD77" },
    {key: "pastel pink",  value: "#FFD1DC" },
    {key: "patina",  value: "#639A8F" },
    {key: "pattens blue",  value: "#DEF5FF" },
    {key: "paua",  value: "#260368" },
    {key: "pavlova",  value: "#D7C498" },
    {key: "peach",  value: "#FFE5B4" },
    {key: "peach cream",  value: "#FFF0DB" },
    {key: "peach orange",  value: "#FFCC99" },
    {key: "peach schnapps",  value: "#FFDCD6" },
    {key: "peach yellow",  value: "#FADFAD" },
    {key: "peanut",  value: "#782F16" },
    {key: "pear",  value: "#D1E231" },
    {key: "pearl bush",  value: "#E8E0D5" },
    {key: "pearl lusta",  value: "#FCF4DC" },
    {key: "peat",  value: "#716B56" },
    {key: "pelorous",  value: "#3EABBF" },
    {key: "peppermint",  value: "#E3F5E1" },
    {key: "perano",  value: "#A9BEF2" },
    {key: "perfume",  value: "#D0BEF8" },
    {key: "periglacial blue",  value: "#E1E6D6" },
    {key: "periwinkle",  value: "#CCCCFF" },
    {key: "periwinkle gray",  value: "#C3CDE6" },
    {key: "persian blue",  value: "#1C39BB" },
    {key: "persian green",  value: "#00A693" },
    {key: "persian indigo",  value: "#32127A" },
    {key: "persian pink",  value: "#F77FBE" },
    {key: "persian plum",  value: "#701C1C" },
    {key: "persian red",  value: "#CC3333" },
    {key: "persian rose",  value: "#FE28A2" },
    {key: "persimmon",  value: "#FF6B53" },
    {key: "peru tan",  value: "#7F3A02" },
    {key: "pesto",  value: "#7C7631" },
    {key: "petite orchid",  value: "#DB9690" },
    {key: "pewter",  value: "#96A8A1" },
    {key: "pharlap",  value: "#A3807B" },
    {key: "picasso",  value: "#FFF39D" },
    {key: "pickled bean",  value: "#6E4826" },
    {key: "pickled bluewood",  value: "#314459" },
    {key: "picton blue",  value: "#45B1E8" },
    {key: "pig pink",  value: "#FDD7E4" },
    {key: "pigeon post",  value: "#AFBDD9" },
    {key: "pigment indigo",  value: "#4B0082" },
    {key: "pine cone",  value: "#6D5E54" },
    {key: "pine glade",  value: "#C7CD90" },
    {key: "pine green",  value: "#01796F" },
    {key: "pine tree",  value: "#171F04" },
    {key: "pink",  value: "#FFC0CB" },
    {key: "pink flamingo",  value: "#FF66FF" },
    {key: "pink flare",  value: "#E1C0C8" },
    {key: "pink lace",  value: "#FFDDF4" },
    {key: "pink lady",  value: "#FFF1D8" },
    {key: "pink salmon",  value: "#FF91A4" },
    {key: "pink swan",  value: "#BEB5B7" },
    {key: "piper",  value: "#C96323" },
    {key: "pipi",  value: "#FEF4CC" },
    {key: "pippin",  value: "#FFE1DF" },
    {key: "pirate gold",  value: "#BA7F03" },
    {key: "pistachio",  value: "#9DC209" },
    {key: "pixie green",  value: "#C0D8B6" },
    {key: "pizazz",  value: "#FF9000" },
    {key: "pizza",  value: "#C99415" },
    {key: "plantation",  value: "#27504B" },
    {key: "plum",  value: "#843179" },
    {key: "pohutukawa",  value: "#8F021C" },
    {key: "polar",  value: "#E5F9F6" },
    {key: "polo blue",  value: "#8DA8CC" },
    {key: "pomegranate",  value: "#F34723" },
    {key: "pompadour",  value: "#660045" },
    {key: "porcelain",  value: "#EFF2F3" },
    {key: "porsche",  value: "#EAAE69" },
    {key: "port gore",  value: "#251F4F" },
    {key: "portafino",  value: "#FFFFB4" },
    {key: "portage",  value: "#8B9FEE" },
    {key: "portica",  value: "#F9E663" },
    {key: "pot pourri",  value: "#F5E7E2" },
    {key: "potters clay",  value: "#8C5738" },
    {key: "powder ash",  value: "#BCC9C2" },
    {key: "powder blue",  value: "#B0E0E6" },
    {key: "prairie sand",  value: "#9A3820" },
    {key: "prelude",  value: "#D0C0E5" },
    {key: "prim",  value: "#F0E2EC" },
    {key: "primrose",  value: "#EDEA99" },
    {key: "provincial pink",  value: "#FEF5F1" },
    {key: "prussian blue",  value: "#003153" },
    {key: "puce",  value: "#CC8899" },
    {key: "pueblo",  value: "#7D2C14" },
    {key: "puerto rico",  value: "#3FC1AA" },
    {key: "pumice",  value: "#C2CAC4" },
    {key: "pumpkin",  value: "#FF7518" },
    {key: "pumpkin skin",  value: "#B1610B" },
    {key: "punch",  value: "#DC4333" },
    {key: "punga",  value: "#4D3D14" },
    {key: "purple",  value: "#660099" },
    {key: "purple heart",  value: "#652DC1" },
    {key: "purple mountain majesty",  value: "#9678B6" },
    {key: "purple pizzazz",  value: "#FF00CC" },
    {key: "putty",  value: "#E7CD8C" },
    {key: "quarter pearl lusta",  value: "#FFFDF4" },
    {key: "quarter spanish white",  value: "#F7F2E1" },
    {key: "quicksand",  value: "#BD978E" },
    {key: "quill gray",  value: "#D6D6D1" },
    {key: "quincy",  value: "#623F2D" },
    {key: "racing green",  value: "#0C1911" },
    {key: "radical red",  value: "#FF355E" },
    {key: "raffia",  value: "#EADAB8" },
    {key: "rainee",  value: "#B9C8AC" },
    {key: "rajah",  value: "#F7B668" },
    {key: "rangitoto",  value: "#2E3222" },
    {key: "rangoon green",  value: "#1C1E13" },
    {key: "raven",  value: "#727B89" },
    {key: "raw sienna",  value: "#D27D46" },
    {key: "raw umber",  value: "#734A12" },
    {key: "razzle dazzle rose",  value: "#FF33CC" },
    {key: "razzmatazz",  value: "#E30B5C" },
    {key: "rebel",  value: "#3C1206" },
    {key: "red",  value: "#FF0000" },
    {key: "red beech",  value: "#7B3801" },
    {key: "red berry",  value: "#8E0000" },
    {key: "red damask",  value: "#DA6A41" },
    {key: "red devil",  value: "#860111" },
    {key: "red orange",  value: "#FF3F34" },
    {key: "red oxide",  value: "#6E0902" },
    {key: "red ribbon",  value: "#ED0A3F" },
    {key: "red robin",  value: "#80341F" },
    {key: "red stage",  value: "#D05F04" },
    {key: "red violet",  value: "#C71585" },
    {key: "redwood",  value: "#5D1E0F" },
    {key: "reef",  value: "#C9FFA2" },
    {key: "reef gold",  value: "#9F821C" },
    {key: "regal blue",  value: "#013F6A" },
    {key: "regent gray",  value: "#86949F" },
    {key: "regent st blue",  value: "#AAD6E6" },
    {key: "remy",  value: "#FEEBF3" },
    {key: "reno sand",  value: "#A86515" },
    {key: "resolution blue",  value: "#002387" },
    {key: "revolver",  value: "#2C1632" },
    {key: "rhino",  value: "#2E3F62" },
    {key: "rice cake",  value: "#FFFEF0" },
    {key: "rice flower",  value: "#EEFFE2" },
    {key: "rich gold",  value: "#A85307" },
    {key: "rio grande",  value: "#BBD009" },
    {key: "ripe lemon",  value: "#F4D81C" },
    {key: "ripe plum",  value: "#410056" },
    {key: "riptide",  value: "#8BE6D8" },
    {key: "river bed",  value: "#434C59" },
    {key: "rob roy",  value: "#EAC674" },
    {key: "robin egg blue",  value: "#00CCCC" },
    {key: "rock",  value: "#4D3833" },
    {key: "rock blue",  value: "#9EB1CD" },
    {key: "rock spray",  value: "#BA450C" },
    {key: "rodeo dust",  value: "#C9B29B" },
    {key: "rolling stone",  value: "#747D83" },
    {key: "roman",  value: "#DE6360" },
    {key: "roman coffee",  value: "#795D4C" },
    {key: "romance",  value: "#FFFEFD" },
    {key: "romantic",  value: "#FFD2B7" },
    {key: "ronchi",  value: "#ECC54E" },
    {key: "roof terracotta",  value: "#A62F20" },
    {key: "rope",  value: "#8E4D1E" },
    {key: "rose",  value: "#FF007F" },
    {key: "rose bud",  value: "#FBB2A3" },
    {key: "rose bud cherry",  value: "#800B47" },
    {key: "rose fog",  value: "#E7BCB4" },
    {key: "rose of sharon",  value: "#BF5500" },
    {key: "rose white",  value: "#FFF6F5" },
    {key: "rosewood",  value: "#65000B" },
    {key: "roti",  value: "#C6A84B" },
    {key: "rouge",  value: "#A23B6C" },
    {key: "royal blue",  value: "#4169E1" },
    {key: "royal heath",  value: "#AB3472" },
    {key: "royal purple",  value: "#6B3FA0" },
    {key: "rum",  value: "#796989" },
    {key: "rum swizzle",  value: "#F9F8E4" },
    {key: "russet",  value: "#80461B" },
    {key: "russett",  value: "#755A57" },
    {key: "rust",  value: "#B7410E" },
    {key: "rustic red",  value: "#480404" },
    {key: "rusty nail",  value: "#86560A" },
    {key: "saddle",  value: "#4C3024" },
    {key: "saddle brown",  value: "#583401" },
    {key: "saffron",  value: "#F4C430" },
    {key: "saffron mango",  value: "#F9BF58" },
    {key: "sage",  value: "#9EA587" },
    {key: "sahara",  value: "#B7A214" },
    {key: "sahara sand",  value: "#F1E788" },
    {key: "sail",  value: "#B8E0F9" },
    {key: "salem",  value: "#097F4B" },
    {key: "salmon",  value: "#FF8C69" },
    {key: "salomie",  value: "#FEDB8D" },
    {key: "salt box",  value: "#685E6E" },
    {key: "saltpan",  value: "#F1F7F2" },
    {key: "sambuca",  value: "#3A2010" },
    {key: "san felix",  value: "#0B6207" },
    {key: "san juan",  value: "#304B6A" },
    {key: "san marino",  value: "#456CAC" },
    {key: "sand dune",  value: "#826F65" },
    {key: "sandal",  value: "#AA8D6F" },
    {key: "sandrift",  value: "#AB917A" },
    {key: "sandstone",  value: "#796D62" },
    {key: "sandwisp",  value: "#F5E7A2" },
    {key: "sandy beach",  value: "#FFEAC8" },
    {key: "sandy brown",  value: "#F4A460" },
    {key: "sangria",  value: "#92000A" },
    {key: "sanguine brown",  value: "#8D3D38" },
    {key: "santa fe",  value: "#B16D52" },
    {key: "santas gray",  value: "#9FA0B1" },
    {key: "sapling",  value: "#DED4A4" },
    {key: "sapphire",  value: "#2F519E" },
    {key: "saratoga",  value: "#555B10" },
    {key: "satin linen",  value: "#E6E4D4" },
    {key: "sauvignon",  value: "#FFF5F3" },
    {key: "sazerac",  value: "#FFF4E0" },
    {key: "scampi",  value: "#675FA6" },
    {key: "scandal",  value: "#CFFAF4" },
    {key: "scarlet",  value: "#FF2400" },
    {key: "scarlet gum",  value: "#431560" },
    {key: "scarlett",  value: "#950015" },
    {key: "scarpa flow",  value: "#585562" },
    {key: "schist",  value: "#A9B497" },
    {key: "school bus yellow",  value: "#FFD800" },
    {key: "schooner",  value: "#8B847E" },
    {key: "science blue",  value: "#0066CC" },
    {key: "scooter",  value: "#2EBFD4" },
    {key: "scorpion",  value: "#695F62" },
    {key: "scotch mist",  value: "#FFFBDC" },
    {key: "screamin green",  value: "#66FF66" },
    {key: "sea buckthorn",  value: "#FBA129" },
    {key: "sea green",  value: "#2E8B57" },
    {key: "sea mist",  value: "#C5DBCA" },
    {key: "sea nymph",  value: "#78A39C" },
    {key: "sea pink",  value: "#ED989E" },
    {key: "seagull",  value: "#80CCEA" },
    {key: "seance",  value: "#731E8F" },
    {key: "seashell",  value: "#F1F1F1" },
    {key: "seashell peach",  value: "#FFF5EE" },
    {key: "seaweed",  value: "#1B2F11" },
    {key: "selago",  value: "#F0EEFD" },
    {key: "selective yellow",  value: "#FFBA00" },
    {key: "sepia",  value: "#704214" },
    {key: "sepia black",  value: "#2B0202" },
    {key: "sepia skin",  value: "#9E5B40" },
    {key: "serenade",  value: "#FFF4E8" },
    {key: "shadow",  value: "#837050" },
    {key: "shadow green",  value: "#9AC2B8" },
    {key: "shady lady",  value: "#AAA5A9" },
    {key: "shakespeare",  value: "#4EABD1" },
    {key: "shalimar",  value: "#FBFFBA" },
    {key: "shamrock",  value: "#33CC99" },
    {key: "shark",  value: "#25272C" },
    {key: "sherpa blue",  value: "#004950" },
    {key: "sherwood green",  value: "#02402C" },
    {key: "shilo",  value: "#E8B9B3" },
    {key: "shingle fawn",  value: "#6B4E31" },
    {key: "ship cove",  value: "#788BBA" },
    {key: "ship gray",  value: "#3E3A44" },
    {key: "shiraz",  value: "#B20931" },
    {key: "shocking",  value: "#E292C0" },
    {key: "shocking pink",  value: "#FC0FC0" },
    {key: "shuttle gray",  value: "#5F6672" },
    {key: "siam",  value: "#646A54" },
    {key: "sidecar",  value: "#F3E7BB" },
    {key: "silk",  value: "#BDB1A8" },
    {key: "silver",  value: "#C0C0C0" },
    {key: "silver chalice",  value: "#ACACAC" },
    {key: "silver rust",  value: "#C9C0BB" },
    {key: "silver sand",  value: "#BFC1C2" },
    {key: "silver tree",  value: "#66B58F" },
    {key: "sinbad",  value: "#9FD7D3" },
    {key: "siren",  value: "#7A013A" },
    {key: "sirocco",  value: "#718080" },
    {key: "sisal",  value: "#D3CBBA" },
    {key: "skeptic",  value: "#CAE6DA" },
    {key: "sky blue",  value: "#76D7EA" },
    {key: "slate gray",  value: "#708090" },
    {key: "smalt",  value: "#003399" },
    {key: "smalt blue",  value: "#51808F" },
    {key: "smoky",  value: "#605B73" },
    {key: "snow drift",  value: "#F7FAF7" },
    {key: "snow flurry",  value: "#E4FFD1" },
    {key: "snowy mint",  value: "#D6FFDB" },
    {key: "snuff",  value: "#E2D8ED" },
    {key: "soapstone",  value: "#FFFBF9" },
    {key: "soft amber",  value: "#D1C6B4" },
    {key: "soft peach",  value: "#F5EDEF" },
    {key: "solid pink",  value: "#893843" },
    {key: "solitaire",  value: "#FEF8E2" },
    {key: "solitude",  value: "#EAF6FF" },
    {key: "sorbus",  value: "#FD7C07" },
    {key: "sorrell brown",  value: "#CEB98F" },
    {key: "soya bean",  value: "#6A6051" },
    {key: "spanish green",  value: "#819885" },
    {key: "spectra",  value: "#2F5A57" },
    {key: "spice",  value: "#6A442E" },
    {key: "spicy mix",  value: "#885342" },
    {key: "spicy mustard",  value: "#74640D" },
    {key: "spicy pink",  value: "#816E71" },
    {key: "spindle",  value: "#B6D1EA" },
    {key: "spray",  value: "#79DEEC" },
    {key: "spring green",  value: "#00FF7F" },
    {key: "spring leaves",  value: "#578363" },
    {key: "spring rain",  value: "#ACCBB1" },
    {key: "spring sun",  value: "#F6FFDC" },
    {key: "spring wood",  value: "#F8F6F1" },
    {key: "sprout",  value: "#C1D7B0" },
    {key: "spun pearl",  value: "#AAABB7" },
    {key: "squirrel",  value: "#8F8176" },
    {key: "st tropaz",  value: "#2D569B" },
    {key: "stack",  value: "#8A8F8A" },
    {key: "star dust",  value: "#9F9F9C" },
    {key: "stark white",  value: "#E5D7BD" },
    {key: "starship",  value: "#ECF245" },
    {key: "steel blue",  value: "#4682B4" },
    {key: "steel gray",  value: "#262335" },
    {key: "stiletto",  value: "#9C3336" },
    {key: "stonewall",  value: "#928573" },
    {key: "storm dust",  value: "#646463" },
    {key: "storm gray",  value: "#717486" },
    {key: "stratos",  value: "#000741" },
    {key: "straw",  value: "#D4BF8D" },
    {key: "strikemaster",  value: "#956387" },
    {key: "stromboli",  value: "#325D52" },
    {key: "studio",  value: "#714AB2" },
    {key: "submarine",  value: "#BAC7C9" },
    {key: "sugar cane",  value: "#F9FFF6" },
    {key: "sulu",  value: "#C1F07C" },
    {key: "summer green",  value: "#96BBAB" },
    {key: "sun",  value: "#FBAC13" },
    {key: "sundance",  value: "#C9B35B" },
    {key: "sundown",  value: "#FFB1B3" },
    {key: "sunflower",  value: "#E4D422" },
    {key: "sunglo",  value: "#E16865" },
    {key: "sunglow",  value: "#FFCC33" },
    {key: "sunset orange",  value: "#FE4C40" },
    {key: "sunshade",  value: "#FF9E2C" },
    {key: "supernova",  value: "#FFC901" },
    {key: "surf",  value: "#BBD7C1" },
    {key: "surf crest",  value: "#CFE5D2" },
    {key: "surfie green",  value: "#0C7A79" },
    {key: "sushi",  value: "#87AB39" },
    {key: "suva gray",  value: "#888387" },
    {key: "swamp",  value: "#001B1C" },
    {key: "swamp green",  value: "#ACB78E" },
    {key: "swans down",  value: "#DCF0EA" },
    {key: "sweet corn",  value: "#FBEA8C" },
    {key: "sweet pink",  value: "#FD9FA2" },
    {key: "swirl",  value: "#D3CDC5" },
    {key: "swiss coffee",  value: "#DDD6D5" },
    {key: "sycamore",  value: "#908D39" },
    {key: "tabasco",  value: "#A02712" },
    {key: "tacao",  value: "#EDB381" },
    {key: "tacha",  value: "#D6C562" },
    {key: "tahiti gold",  value: "#E97C07" },
    {key: "tahuna sands",  value: "#EEF0C8" },
    {key: "tall poppy",  value: "#B32D29" },
    {key: "tallow",  value: "#A8A589" },
    {key: "tamarillo",  value: "#991613" },
    {key: "tamarind",  value: "#341515" },
    {key: "tan",  value: "#D2B48C" },
    {key: "tan hide",  value: "#FA9D5A" },
    {key: "tana",  value: "#D9DCC1" },
    {key: "tangaroa",  value: "#03163C" },
    {key: "tangerine",  value: "#F28500" },
    {key: "tango",  value: "#ED7A1C" },
    {key: "tapa",  value: "#7B7874" },
    {key: "tapestry",  value: "#B05E81" },
    {key: "tara",  value: "#E1F6E8" },
    {key: "tarawera",  value: "#073A50" },
    {key: "tasman",  value: "#CFDCCF" },
    {key: "taupe",  value: "#483C32" },
    {key: "taupe gray",  value: "#B3AF95" },
    {key: "tawny port",  value: "#692545" },
    {key: "te papa green",  value: "#1E433C" },
    {key: "tea",  value: "#C1BAB0" },
    {key: "tea green",  value: "#D0F0C0" },
    {key: "teak",  value: "#B19461" },
    {key: "teal",  value: "#008080" },
    {key: "teal blue",  value: "#044259" },
    {key: "temptress",  value: "#3B000B" },
    {key: "tenn",  value: "#CD5700" },
    {key: "tequila",  value: "#FFE6C7" },
    {key: "terracotta",  value: "#E2725B" },
    {key: "texas",  value: "#F8F99C" },
    {key: "texas rose",  value: "#FFB555" },
    {key: "thatch",  value: "#B69D98" },
    {key: "thatch green",  value: "#403D19" },
    {key: "thistle",  value: "#D8BFD8" },
    {key: "thistle green",  value: "#CCCAA8" },
    {key: "thunder",  value: "#33292F" },
    {key: "thunderbird",  value: "#C02B18" },
    {key: "tia maria",  value: "#C1440E" },
    {key: "tiara",  value: "#C3D1D1" },
    {key: "tiber",  value: "#063537" },
    {key: "tickle me pink",  value: "#FC80A5" },
    {key: "tidal",  value: "#F1FFAD" },
    {key: "tide",  value: "#BFB8B0" },
    {key: "timber green",  value: "#16322C" },
    {key: "timberwolf",  value: "#D9D6CF" },
    {key: "titan white",  value: "#F0EEFF" },
    {key: "toast",  value: "#9A6E61" },
    {key: "tobacco brown",  value: "#715D47" },
    {key: "toledo",  value: "#3A0020" },
    {key: "tolopea",  value: "#1B0245" },
    {key: "tom thumb",  value: "#3F583B" },
    {key: "tonys pink",  value: "#E79F8C" },
    {key: "topaz",  value: "#7C778A" },
    {key: "torch red",  value: "#FD0E35" },
    {key: "torea bay",  value: "#0F2D9E" },
    {key: "tory blue",  value: "#1450AA" },
    {key: "tosca",  value: "#8D3F3F" },
    {key: "totem pole",  value: "#991B07" },
    {key: "tower gray",  value: "#A9BDBF" },
    {key: "tradewind",  value: "#5FB3AC" },
    {key: "tranquil",  value: "#E6FFFF" },
    {key: "travertine",  value: "#FFFDE8" },
    {key: "tree poppy",  value: "#FC9C1D" },
    {key: "treehouse",  value: "#3B2820" },
    {key: "trendy green",  value: "#7C881A" },
    {key: "trendy pink",  value: "#8C6495" },
    {key: "trinidad",  value: "#E64E03" },
    {key: "tropical blue",  value: "#C3DDF9" },
    {key: "tropical rain forest",  value: "#00755E" },
    {key: "trout",  value: "#4A4E5A" },
    {key: "true v",  value: "#8A73D6" },
    {key: "tuatara",  value: "#363534" },
    {key: "tuft bush",  value: "#FFDDCD" },
    {key: "tulip tree",  value: "#EAB33B" },
    {key: "tumbleweed",  value: "#DEA681" },
    {key: "tuna",  value: "#353542" },
    {key: "tundora",  value: "#4A4244" },
    {key: "turbo",  value: "#FAE600" },
    {key: "turkish rose",  value: "#B57281" },
    {key: "turmeric",  value: "#CABB48" },
    {key: "turquoise",  value: "#30D5C8" },
    {key: "turquoise blue",  value: "#6CDAE7" },
    {key: "turtle green",  value: "#2A380B" },
    {key: "tuscany",  value: "#BD5E2E" },
    {key: "tusk",  value: "#EEF3C3" },
    {key: "tussock",  value: "#C5994B" },
    {key: "tutu",  value: "#FFF1F9" },
    {key: "twilight",  value: "#E4CFDE" },
    {key: "twilight blue",  value: "#EEFDFF" },
    {key: "twine",  value: "#C2955D" },
    {key: "tyrian purple",  value: "#66023C" },
    {key: "ultramarine",  value: "#120A8F" },
    {key: "valencia",  value: "#D84437" },
    {key: "valentino",  value: "#350E42" },
    {key: "valhalla",  value: "#2B194F" },
    {key: "van cleef",  value: "#49170C" },
    {key: "vanilla",  value: "#D1BEA8" },
    {key: "vanilla ice",  value: "#F3D9DF" },
    {key: "varden",  value: "#FFF6DF" },
    {key: "venetian red",  value: "#72010F" },
    {key: "venice blue",  value: "#055989" },
    {key: "venus",  value: "#928590" },
    {key: "verdigris",  value: "#5D5E37" },
    {key: "verdun green",  value: "#495400" },
    {key: "vermilion",  value: "#FF4D00" },
    {key: "vesuvius",  value: "#B14A0B" },
    {key: "victoria",  value: "#534491" },
    {key: "vida loca",  value: "#549019" },
    {key: "viking",  value: "#64CCDB" },
    {key: "vin rouge",  value: "#983D61" },
    {key: "viola",  value: "#CB8FA9" },
    {key: "violent violet",  value: "#290C5E" },
    {key: "violet",  value: "#240A40" },
    {key: "violet eggplant",  value: "#991199" },
    {key: "violet red",  value: "#F7468A" },
    {key: "viridian",  value: "#40826D" },
    {key: "viridian green",  value: "#678975" },
    {key: "vis vis",  value: "#FFEFA1" },
    {key: "vista blue",  value: "#8FD6B4" },
    {key: "vista white",  value: "#FCF8F7" },
    {key: "vivid tangerine",  value: "#FF9980" },
    {key: "vivid violet",  value: "#803790" },
    {key: "voodoo",  value: "#533455" },
    {key: "vulcan",  value: "#10121D" },
    {key: "wafer",  value: "#DECBC6" },
    {key: "waikawa gray",  value: "#5A6E9C" },
    {key: "waiouru",  value: "#363C0D" },
    {key: "walnut",  value: "#773F1A" },
    {key: "wasabi",  value: "#788A25" },
    {key: "water leaf",  value: "#A1E9DE" },
    {key: "watercourse",  value: "#056F57" },
    {key: "waterloo ",  value: "#7B7C94" },
    {key: "wattle",  value: "#DCD747" },
    {key: "watusi",  value: "#FFDDCF" },
    {key: "wax flower",  value: "#FFC0A8" },
    {key: "we peep",  value: "#F7DBE6" },
    {key: "web orange",  value: "#FFA500" },
    {key: "wedgewood",  value: "#4E7F9E" },
    {key: "well read",  value: "#B43332" },
    {key: "west coast",  value: "#625119" },
    {key: "west side",  value: "#FF910F" },
    {key: "westar",  value: "#DCD9D2" },
    {key: "wewak",  value: "#F19BAB" },
    {key: "wheat",  value: "#F5DEB3" },
    {key: "wheatfield",  value: "#F3EDCF" },
    {key: "whiskey",  value: "#D59A6F" },
    {key: "whisper",  value: "#F7F5FA" },
    {key: "white",  value: "#FFFFFF" },
    {key: "white ice",  value: "#DDF9F1" },
    {key: "white lilac",  value: "#F8F7FC" },
    {key: "white linen",  value: "#F8F0E8" },
    {key: "white pointer",  value: "#FEF8FF" },
    {key: "white rock",  value: "#EAE8D4" },
    {key: "wild blue yonder",  value: "#7A89B8" },
    {key: "wild rice",  value: "#ECE090" },
    {key: "wild sand",  value: "#F4F4F4" },
    {key: "wild strawberry",  value: "#FF3399" },
    {key: "wild watermelon",  value: "#FD5B78" },
    {key: "wild willow",  value: "#B9C46A" },
    {key: "william",  value: "#3A686C" },
    {key: "willow brook",  value: "#DFECDA" },
    {key: "willow grove",  value: "#65745D" },
    {key: "windsor",  value: "#3C0878" },
    {key: "wine berry",  value: "#591D35" },
    {key: "winter hazel",  value: "#D5D195" },
    {key: "wisp pink",  value: "#FEF4F8" },
    {key: "wisteria",  value: "#9771B5" },
    {key: "wistful",  value: "#A4A6D3" },
    {key: "witch haze",  value: "#FFFC99" },
    {key: "wood bark",  value: "#261105" },
    {key: "woodland",  value: "#4D5328" },
    {key: "woodrush",  value: "#302A0F" },
    {key: "woodsmoke",  value: "#0C0D0F" },
    {key: "woody brown",  value: "#483131" },
    {key: "xanadu",  value: "#738678" },
    {key: "yellow",  value: "#FFFF00" },
    {key: "yellow green",  value: "#C5E17A" },
    {key: "yellow metal",  value: "#716338" },
    {key: "yellow orange",  value: "#FFAE42" },
    {key: "yellow sea",  value: "#FEA904" },
    {key: "your pink",  value: "#FFC3C0" },
    {key: "yukon gold",  value: "#7B6608" },
    {key: "yuma",  value: "#CEC291" },
    {key: "zambezi",  value: "#685558" },
    {key: "zanah",  value: "#DAECD6" },
    {key: "zest",  value: "#E5841B" },
    {key: "zeus",  value: "#292319" },
    {key: "ziggurat",  value: "#BFDBE2" },
    {key: "zinnwaldite",  value: "#EBC2AF" },
    {key: "zircon",  value: "#F4F8FF" },
    {key: "zombie",  value: "#E4D69B" },
    {key: "zorba",  value: "#A59B91" },
    {key: "zuccini",  value: "#044022" },
    {key: "zumthor",  value: "#EDF6FF"},
]

const sizes = {
    "default": [],
	"male": {
		"Shirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"T Shirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Sweatshirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Kurtas & Kurta Sets": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Jackets & Coats": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Sweaters": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Blazers & Waistcoats": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Suits": generateKeyValue(["XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Sherwanis": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Track Shirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Briefs & Trunks": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Boxers": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Vests": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Robes": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Bath Robes": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Lounge T-Shirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
        "Jumpsuits": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
		"Night Suits": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Thermal Bottoms": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Thermal Sets": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Thermal Tops": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Swim Bottoms": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Swim Suits": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Track Suits": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Jeans": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Trousers": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Shorts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Joggers": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Shorts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Track Pants & Shorts": generateKeyValue(["26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Dhotis": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
		"Dhoti Pants": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
		"Lounge Pants": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Lounge Shorts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Pyjamas": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Belts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Formal Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Casual Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Sports Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Boots": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Outdoor Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Work & Safety Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Ethnic Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
        "Flipflops & Flats": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13"]),
        "Sandals & Floaters": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13"]),
        "Ethnic Shoes": generateKeyValue(["IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","IND-11","IND-12","IND-13"]),
		"Watch": generateKeyValue(["18 mm","20 mm","22 mm","24 mm","30 mm","32 mm","34 mm","36 mm","40 mm","42 mm","44 mm","46 mm"]),
        "Rings": generateKeyValue(["7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","Free Size"]),
	},
	"female": {
		"Shirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"T Shirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Sweatshirts": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Kurtas & Kurta Sets": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Jackets & Coats": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Sweaters": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Blazers & Waistcoats": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","24","26","28","30","32","34","36","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Suits": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Semi Stitched","Unstitched","Free Size"]),
        "Kurtis, Tunics": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
        "Palazzos": generateKeyValue(["24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size","XS","S","M","L","XL","XXL"]),
        "Skirts": generateKeyValue(["24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size","XS","S","M","L","XL","XXL"]),
        "Shrugs & Blouses": generateKeyValue(["26","26 Alterable","28","28 Alterable","30","30 Alterable","32","32 Alterable","34","34 Alterable","36","36 Alterable","38","38 Alterable","40","40 Alterable","42","42 Alterable","44","44 Alterable","46","46 Alterable","48","48 Alterable","50","50 Alterable","52","52 Alterable","54","54 Alterable","56","56 Alterable","58","58 Alterable","60","60 Alterable"]),
		"Jeans": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
        "Tights, Leggings & Jeggings": generateKeyValue(["24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size","XS","S","M","L","XL","XXL"]),
		"Capris": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Shorts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
        "Lehenga Cholis": generateKeyValue(["Semi Stitched","Unstitched","Free Size"]),
        "Dupattas & Shawls": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
        "Bra": generateKeyValue(["28A","30A","32A","34A","36A","38A","40A","42A","44A","46A","48A","50A","52A","54A","28B","30B","32B","34B","36B","38B","40B","42B","44B","46B","48B","50B","52B","54B","28C","30C","32C","34C","36C","38C","40C","42C","44C","46C","48C","50C","52C","54C","28D","30D","32D","34D","36D","38D","40D","42D","44D","46D","48D","50D","52D","54D","28E","30E","32E","34E","36E","38E","40E","42E","44E","46E","48E","50E","52E","54E","30F","32F","34F","40F","42F","44F","46F","48F","50F","52F","54F","40G","42G","44G","46G","48G","50G","52G","54G","40H","42H","44H","46H","48H","50H","52H","54H","40I","42I","44I","46I","48I","50I","52I","54I","40J","42J","44J","46J","48J","50J","52J","54J","40K","42K","44K","46K","48K","50K","52K","54K","40AA","44AA","46AA","48AA","50AA","52AA","54AA","30DD","32DD","34DD","40DD","44DD","46DD","48DD","50DD","52DD","54DD","40EE","42EE","44EE","46EE","48EE","50EE","52EE","54EE","30FF","32FF","34FF","40FF","42FF","44FF","46FF","48FF","50FF","52FF","54FF","40GG","42GG","44GG","46GG","48GG","50GG","52GG","54GG","40DDD","44DDD","46DDD","48DDD","50DDD","52DDD","54DDD","XS","S","M","L","XL","XXL","XXXL","Free Size"]),
        "Shapewear": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
        "Camisoles": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
        "Lingerie Sets & Accessories": generateKeyValue(["28A","30A","32A","34A","36A","38A","40A","42A","44A","46A","48A","50A","52A","54A","28B","30B","32B","34B","36B","38B","40B","42B","44B","46B","48B","50B","52B","54B","28C","30C","32C","34C","36C","38C","40C","42C","44C","46C","48C","50C","52C","54C","28D","30D","32D","34D","36D","38D","40D","42D","44D","46D","48D","50D","52D","54D","28E","30E","32E","34E","36E","38E","40E","42E","44E","46E","48E","50E","52E","54E","30F","32F","34F","40F","42F","44F","46F","48F","50F","52F","54F","40G","42G","44G","46G","48G","50G","52G","54G","40H","42H","44H","46H","48H","50H","52H","54H","40I","42I","44I","46I","48I","50I","52I","54I","40J","42J","44J","46J","48J","50J","52J","54J","40K","42K","44K","46K","48K","50K","52K","54K","40AA","44AA","46AA","48AA","50AA","52AA","54AA","30DD","32DD","34DD","40DD","44DD","46DD","48DD","50DD","52DD","54DD","40EE","42EE","44EE","46EE","48EE","50EE","52EE","54EE","30FF","32FF","34FF","40FF","42FF","44FF","46FF","48FF","50FF","52FF","54FF","40GG","42GG","44GG","46GG","48GG","50GG","52GG","54GG","40DDD","44DDD","46DDD","48DDD","50DDD","52DDD","54DDD","XS","S","M","L","XL","XXL","XXXL","Free Size"]),
        "Tops": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
        "Jumpsuits": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","Free Size"]),
		"Night Suits": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Thermal Bottoms": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Thermal Sets": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Thermal Tops": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Swim Bottoms": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Swim Suits": generateKeyValue(["XXS","XS","S","M","L","XL","2XL","3XL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","38","39","40","42","44","46","48","50","52","Free Size"]),
		"Track Suits": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size"]),
		"Joggers": generateKeyValue(["26","28","30","32","34","38","36","39","40","42","44","46","48","50"]),
		"Track Pants & Shorts": generateKeyValue(["26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
        "Churidars": generateKeyValue(["24","26","28","30","32","34","36","38","40","42","44","46","48","50","52","Free Size","XS","S","M","L","XL","XXL"]),
		"Salwars": generateKeyValue(["26","28","30","32","34","38","36","39","40","42","44","46","48","50","Free Size"]),
		"Harem Pants": generateKeyValue(["26","28","30","32","34","38","36","39","40","42","44","46","48","50"]),
		"Lounge Pants": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Lounge Shorts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
        "Pyjamas": generateKeyValue(["XXS","XS","S","M","L","XL","XXL","XXXL","4XL","5XL","6XL","7XL","8XL","9XL","10XL","26","28","30","32","34","38","36","39","40","42","44","46","48","50","Free Size"]),
		"Belts": generateKeyValue(["XS","S","M","L","XL","XXL","24","26","28","30","32","34","38","36","39","40","42","44","46","48","50","52","Free Size"]),
		"Formal Shoes": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Casual Shoes": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Sports Shoes": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Boots": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Outdoor Shoes": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Work & Safety Shoes": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
		"Ethnic Shoes": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10","UK1","UK1.5","UK2","UK2.5","UK3","UK3.5","UK4","UK4.5","UK5","UK5.5","UK6","UK6.5","UK7","UK7.5","UK8","UK8.5","UK9","UK9.5","UK10","UK10.5","UK11","UK11.5","UK12","UK12.5","UK13","UK13.5"]),
        "Flipflops & Flats": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10"]),
        "Sandals & Floaters": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10"]),
        "Heels": generateKeyValue(["IND-2","IND-3","IND-4","IND-5","IND-6","IND-7","IND-8","IND-9","IND-10"]),
		"Watch": generateKeyValue(["18 mm","20 mm","22 mm","24 mm","30 mm","32 mm","34 mm","36 mm","40 mm","42 mm","44 mm","46 mm"]),
        "Rings": generateKeyValue(["7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","Free Size"])
	},
	"girl": {
        "Shirts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "T Shirts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Sweatshirts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Kurtas & Kurta Sets": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Jackets & Coats": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Sweaters": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Skirts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Shrugs & Blouses": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Jeans": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Tights, Leggings & Jeggings": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Tops": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Jumpsuits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Night Suits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Dresses": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Thermal Bottoms": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Thermal Sets": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Thermal Tops": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Capris": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Shorts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Track Pants & Shorts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Pyjamas": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Swim Suits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Gloves": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Earmuffs": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
	},
	"boy": {
        "Shirts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "T Shirts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Sweatshirts & Hoodies": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Kurta Sets": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
         "Jackets & Coats": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
         "Suits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
         "Sherwanis": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
         "Sweaters": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
         "Jeans": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Jumpsuits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Night Suits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Dresses": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Thermal Bottoms": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Thermal Sets": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Thermal Tops": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Shorts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Track Pants & Shorts": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Pyjamas": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
        "Swim Suits": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Gloves": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
		"Earmuffs": generateKeyValue(["0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","14-15 Years","15-16 Years","Free Size"]),
	}
}

export const propertyEnums = {
    "color": colors,
    "gender": generateKeyValue(["male","female","boy","girl","infant","unisex"]),
    "backpack_style": generateKeyValue(["buckle","button","drawstring","flap","hook & loop","magnetic","push lock","snap","twist lock","zipper"]),
    "base_metal": generateKeyValue(["alpha","gold","silver","brass","alloy","ceramic","wood","metal","german silver","leather","plastic","PU"]),
    "benefit": generateKeyValue(["age_defying","curling","HD_photogenic","lengthening","luminous","pore_minimising","thickening","volumizing","waterproof","long_lasting","plumping","transfer_proof","transfer_resistant","growth_boosting","cuticle_care","hydrating","strengthening","repairing"]),
    "bottom_type": generateKeyValue(["capri","ethnic bottom","jeans","lounge pant","short","track pant","trouser"]),
    "bristle_type": generateKeyValue(["natural","synthetic"]),
    "brush_type": generateKeyValue(["foundation","stippling","concealer","powder","blush","contour","bronzer","highlight","eyeshadow","eyeshadow-crease","eyeshadow-blending","eye-liner","mascara","lip"]),
    "buckle_material": generateKeyValue(["alpha","metal","leather"]),
    "buckle_type": generateKeyValue(["alpha","cam","ratchet","roller","side release","slide","snap","tie"]),
    "closure_type": generateKeyValue(["alpha","back","button","front","full-zip","half-zip","no-closure","quarter-zip","side","slip-on","tie-up"]),
    "collar": generateKeyValue(["alpha","button down","mandarin","club","spread","slim","peter pan","hood","cutaway","cuban","wingtip","built-up"]),
    "compatible_devices": generateKeyValue(["laptop","desktop","tablet"]),
    "concern": generateKeyValue(["acne_blemish","anti_ageing","anti_pollution","bad_breath","blackhead_whitehead","brightening","cavity","chapped_lip","cleansing","colour_protection","cuticle_care","dandruff","dark_spot_pigmentation","dry_frizzy_hair","dryness","dullness","fine_lines_wrinkle","firming","hairfall_thinning","heat_protection","hydration","menstrual_cycle","odour","oil_control","plaque","pores","product_build_up","soap_free","split_end","stretch_mark","sun_protection","tan_removal","tangled_hair","under_eye","uneven_skin_tone","waterproof"]),
    "connectivity": generateKeyValue(["bluetooth","LTE","wi-fi","wireless","USB"]),

    "conscious": generateKeyValue(["cruelty_free","vegan","natural"]),
    "coverage": generateKeyValue(["alpha","full","half","medium","low","sheer"]),
    "dial_shape": generateKeyValue(["alpha","asymmetric","diamond","oval","rectangle","round","square","triangle","thread"]),
    "dimension_unit": generateKeyValue(["centimeter","meter"]),
    "display": generateKeyValue(["alpha","analog","digital"]),
    "fabric": generateKeyValue(["alpha","acrylic","art silk","bamboo","chambray","chanderi cotton","chanderi silk","chiffon","cotton","cotton blend","cotton cambric","cotton linen","cotton silk","crepe","denim","dupion silk","elastane","georgette","jacquard","jute","jute cotton","jute silk","khadi cotton","kora muslin","lace","leather","linen","mulmul","modal","net","nylon","organza","paper silk","pashmina","poly chiffon","poly crepe","poly georgette","poly silk","polycotton","polyester","rayon","rayon slub","satin","shantoon","silk","silk blend","soft silk","super net","synthetic","taffeta silk","tissue","tussar silk","velvet","vichitra silk","viscose","viscose rayon","voile","wool"]),
    "fabric_finish": generateKeyValue(["alpha","satin","crepe","merserised","printed","painted","embroidered","patchwork","woven","knitted"]),
    "face_shape": generateKeyValue(["alpha","rectangular","oval","circular","square","triangular"]),
    "fasten_type": generateKeyValue(["alpha","ankle loop","backstrap","buckle","closed back","lace-up","no back strap","open back","slip-on","velcro","zip"]),
    "features": generateKeyValue(["alpha","basic","pullover","threebuttoned","flared"]),
    "finish": generateKeyValue(["clear","creme","duochrome","gel","glitter","glossy","luminous","matte","metallic","natural","radiant","shimmer","satin","special_effects","tinted"]),
    "fit": generateKeyValue(["alphanumeric","loose","regular","relax","skinny","slim","smart","ultra slim"]),
    "flavour": generateKeyValue(["ajwain","amla","aniseed","apple","asafoetida","assorted","banana","basil","bay leaf","blueberry","butter","caramel","caraway","cardamom","chervil","chillies","chive","chocolate","cilantro","cinnamon","cloves","cola","coriander","corn","cranberry","cumin","curry leaves","dill","elaichi","fennel","fenugreek","fruits","galangal","garlic","ginger","guava","horseradish","imli","jeera","kaffir lime leaf","kasuri methi","kokum","lemon","lemon grass","mace","mango","marjoram","methi","mint","mixed fruit","mushroom","mustard","nutmeg","onion","orange","oregano","parsley","peach","pepper","peppercorn","peppermint","pineapple","pomegranate","raspberry","rosemary","saffron","sage","spearmint","star anise","strawberry","sweetmint","tamarind","tarragon","thyme","turmeric","vanilla","wasabi","watermelon"]),
    "footwear_type": generateKeyValue(["boat shoes","brogues","clogs","comfort sandals","derbys","driving shoes","espadrilles","flat boots","flatforms","gladiators","kolhapuri","loafers","mojaris","monks","oxfords","skate shoes","sliders","slip-on sneakers","sneakers","thong flip-flops","trekking shoes"]),
    "formulation": generateKeyValue(["cream","foam","gel","lotion","paste","powder","wax","liquid","bar","butter","spray","mist","roll_on","serum","solid","mousse","stick","tablet","capsule","mask"]),
    "fragrance": generateKeyValue(["fresh","floral","earthy_woody","warm_spicy","fruity"]),
    "frame_material": generateKeyValue(["acetate","acrylic","alloy","aluminium","carbon fiber","composite","fiber","fiber & plastic","metal","metal & plastic","plastic","polycarbonate","rubber","steel","TR-90","titanium"]),
    "frame_shape": generateKeyValue(["alpha","aviator","butterfly","cat eye","clubmaster","contemporary","geometric","heart","hexagon","oval","oversized","rectangular","round","shield","sports","square","teardrop","wayfarer"]),
    "frame_size": generateKeyValue(["alpha","extra narrow","narrow","medium","wide","extra wide"]),
    "frame_style": generateKeyValue(["classic","trendy","designer","nerdy","retro","sporty"]),
    "frame_type": generateKeyValue(["alpha","full rim","half rim","rimless"]),
    "front_styling": generateKeyValue(["double-breasted","single-breasted","tuxedo"]),
    "gem_type": generateKeyValue(["alpha","crystal","pearl","diamond","american diamond","aquamarine","quartz","zirconia","garnet","sapphire"]),
    "gender": generateKeyValue(["male","female","boy","girl","infant","unisex"]),
    "glass_material": generateKeyValue(["acetate","acrylic","mineral","plastic","sapphire crystal"]),
    "grain_type": generateKeyValue(["broken","long","short","medium"]),
    "hair_type": generateKeyValue(["dry","normal","curly","straight","dull","oily","fine","thick","thin","wavy"]),
    "hemline": generateKeyValue(["asymmetric","curved","high-low","straight","vented"]),
    "ingredient": generateKeyValue(["almond","aloe_vera","argan_oil","avocado","beeswax","beetroot","castor_oil","chamomile","charcoal","clay","coconut","eucalyptus","green_tea","honey","jojoba_oil","lemon_grass","moringa","neem","niacinamide","papaya","pomegranate","rose","salicylic_acid","sandalwood","shea_butter","squalene","sunflower","tea_tree","tulsi","turmeric","vitamin_A","vitamin_C","vitamin_E"]),
    "insole": generateKeyValue(["alpha","arch support","athletic","comfort","orthopedic","rubber","fur","leather","memory foam","padded","support"]),
    "jewellery_type": generateKeyValue(["statement","stone","pearl","kundan","oxidised","tasse","sterling_silver","meenakari"]),
    "lens_material": generateKeyValue(["alpha","composite","glass","plastic","polycarbonate","trivex","high index"]),
    "lock_type": generateKeyValue(["alpha","TSA","combination","numeric"]),
    "material": generateKeyValue(["alpha","acetate","acrylic","acrylonitrile","aluminium","battery","brass","canvas","carbon","cellulose","cork","cotton","elastane","elastodiene","elastolefin","ethylene","EVA","expanded","faux leather","foamed","glass","grey","iron","jute","leather","linen","lycra","lyocell","mesh","modal","nubuck","nylon","polyamide","polycarbonate","polyester","polyethylene","polymethylpentene","polyoxymethylene","polypropylene","polyurethane","polyvinyl","PU","PVC","rayon","rice","rubber","sand","silicon","silk","stainless","steel","styrene","suede","synthetic","textile","thermo","thermoplastic","tritan","velvet","viscose","water","wood","wool","zinc"]),
    "material_finish": generateKeyValue(["alpha","embossed","embroidered"]),
    "midsole": generateKeyValue(["alpha","leather","PU","rubber"]),
    "neck": generateKeyValue(["alpha","boat","choker","cowl","halter","hanley","high","keyhole","mandarin","notch","off-shoulder","one shoulder","peter pan","polo","round","scoop","shawl collar","shirt collar","shoulder strap","square","strapless","stylised","surplice","sweetheart","tie-up","turtle","v-neck"]),
    "occasion": generateKeyValue(["alpha","casual","ethnic casual","ethnic party","festive","formal","others","party","semiformal","semicasual","sports","wedding"]),
    "ornamentation": generateKeyValue(["alpha","aari work","sequinned","phulkari","zari","zardozi","beads & stones","threadwork","chikankari","gota patti","mukaish","patch","kantha","mirror"]),
    "outsole": generateKeyValue(["alpha","marking","non-marking"]),
    "padding": generateKeyValue(["alpha","lightly padded","heavily padded","non padded","removable padding"]),
    "pattern": generateKeyValue(["alpha","abstract","animal","bandhni","camouflage","checked","chevron","colourblocked","dyed","embellished","embroidered","ethnic motif","faded","floral","geometric","graphic print","ikat","lehriya","mesh","microprint","ombre","painted","paisley","perforations","polka dots","printed","quirky","solid","striped","temple","textured","tie-dyed","tribal","typography","woven"]),
    "plating": generateKeyValue(["alpha","brass","copper","gold","rose gold","rhodium","silver"]),
    "power_type": generateKeyValue(["battery","solar"]),
    "preference": generateKeyValue(["acetone_free","alcohol_free","allergy_free","ammonia_free","animal_hair","anti_inflammatory","anti_oxidants","clinically_proven","collagen","cruelty_free","dermatologically_tested","formaldehyde_free","fragrance_free","gluten_free","halal_certified","herbal","hyaluronic_acid","hypoallergenic","mineral_oil_free","natural","niacinamide","non_comedogenic","non_vegetarian","nut_free","oil_free","ophthalmologically_tested","organic","paba_free","paraben_free","paraffin_free","petrochemical_free","propleyne_free","pthalate_free","salicylic_acid","silicone_free","sls_free","soap_free","squalane","sulphate_free","sun_protection","synthetic","synthetic_color_free","vegan","vegetarian","vitamin_A","vitamin_C","vitamin_E","water_resistant","waterproof","waterless"]),
    "preservative": generateKeyValue(["benzoic acid","carvacrol","citric Aaid","edta calcium disodium","ethylene absorber","hydro powder","MSG","oxygen absorber","potassium benzoate","propionic acid","propylene glycol ppg","sodium benzoate","sodium bisulphite","sodium lignosulfate","sodium metabisulfite","sodium propionate","sodium citrate","sorbic acid","tripotassium phosphate","trisodium citrate","vacuum salt"]),
    "seam": generateKeyValue(["alpha","cut_sew","seamless"]),
    "seam_type": generateKeyValue(["alpha","cut_sew","seamless"]),
    "season": generateKeyValue(["alpha","rainy","winter","summer","autumn","fall_winter"]),
    "skin_tone": generateKeyValue(["medium_wheatish","fair_light","dusky_dark"]),
    "skin_type": generateKeyValue(["dry","oily","sensitive","normal","all","very_dry"]),
    "sleeve_length": generateKeyValue(["full","half","long","short","sleeveless","three-quarter"]),
    "socks_length": generateKeyValue(["ankle","knee","leg warmer","mid calf","no show","shoe liner","thigh high"]),
    "sole_material": generateKeyValue(["croslite","eva","leather","neolite","phylon","polyurethane","PU","PVC","resin","rubber","synthetic","TPR","TPU","tunit"]),
    "special_feature": generateKeyValue(["laser","GPS"]),
    "sport_type": generateKeyValue(["alpha","running","walking","basketball","baseball","football","soccer","hockey","lacrosse","rugby","volleyball","training","golf","tennis","cycling","skateboarding"]),
    "stone_type": generateKeyValue(["alpha","precious","semi-precious","artificial"]),
    "storage_type": generateKeyValue(["SSD","HDD"]),
    "strap_material": generateKeyValue(["alloy","beads","brass","canvas","ceramic","fabric","faux leather","kundan","leather","metal","pearl","plastic","PU","resin","rubber","silicon","stainless steel","synthetic","thread"]),
    "strap": generateKeyValue(["alpha","belt","bracelet","chain","cross_body","ergonomic","halter","one shoulder","magnetic","multiway","regular","sternum","strapless","yoke_style"]),
    "sweatshirt_type": generateKeyValue(["hooded","pullover"]),
    "toe_shape": generateKeyValue(["alpha","pointed","round","square"]),
    "top_type": generateKeyValue(["jacket","shirt","sweater","sweatshirt","t-shirt"]),
    "trend": generateKeyValue(["alpha","abstract","geometric","nautica","animal","indigo","colour_block","monochrome","typography","tribal","floral"]),
    "unit": generateKeyValue(["MB","GB","TB"]),
    "uom": generateKeyValue(["unit","dozen","gram","kilogram","tonne","litre","millilitre"]),
    "waist_band": generateKeyValue(["alpha","inner_elastic","outer_elastic"]),
    "waist_rise": generateKeyValue(["alpha","low","high","med"]),
    "wash_type": generateKeyValue(["clean","heavy","light"]),
};

export const allProperties = {
    Fashion: {
        "Shirts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "upload"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "T Shirts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Sweatshirts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Sweatshirt Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sweatshirt_type
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Waist Rise",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.waist_rise
            }
        ],
        "Kurtas & Kurta Sets": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Jackets & Coats": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Front Styling",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.front_styling
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Sweaters": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Suits": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Front Styling",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.front_styling
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            }
        ],
        "Sherwanis": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Track Shirts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Track Suits": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Unstitched Fabrics": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Dresses": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Tops": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Top Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.top_type
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Trousers": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Bottom Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.bottom_type
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Waist Rise",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.waist_rise
            }
        ],
        "Capris": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Bottom Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.bottom_type
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Waist Rise",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.waist_rise
            }
        ],
        "Coordinates": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Playsuits": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Jumpsuits": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Shrugs & Blouses": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Blazers & Waistcoats": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Tights, Leggings & Jeggings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Track Pants": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Jeans": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Waist Rise",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.waist_rise
            }
        ],
        "Shorts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Joggers": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Dhotis & Dhoti Pants": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Churidars": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Salwars": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hemline",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.hemline
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Dungarees & Jumpsuits": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Skirts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hemline",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.hemline
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Waist Rise",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.waist_rise
            }
        ],
        "Clothing Sets": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Belts": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Reversible",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Buckle Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.buckle_material
            },
            {
                "name": "Buckle Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.buckle_type
            }
        ],
        "Caps & Hats": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Kurtis, Tunics": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hemline",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.hemline
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Sarees": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Ethnic Wear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Palazzos": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hemline",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.hemline
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Dress Materials": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Lehenga Cholis": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hemline",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.hemline
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Dupattas & Shawls": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Burqas & Hijabs": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Blouses": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Blouse Pieces": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Briefs": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Boxers": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Vests": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Robes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Night Suits": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Thermal Wear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Swim Bottoms": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Swimwear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Bra": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Coverage",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.coverage
            },
            {
                "name": "Padding",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.padding
            }
        ],
        "Shapewear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Coverage",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.coverage
            },
            {
                "name": "Padding",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.padding
            }
        ],
        "Sleepwear & Loungewear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Camisoles": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Lingerie Sets & Accessories": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Coverage",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.coverage
            },
            {
                "name": "Padding",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.padding
            }
        ],
        "Bath Robes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Towels": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Pyjamas": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Party Wear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Ornamentation",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ornamentation
            }
        ],
        "Innerwear & Sleepwear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Strap Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.strap_type
            },
            {
                "name": "Coverage",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.coverage
            },
            {
                "name": "Padding",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.padding
            },
            {
                "name": "Seam",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.seam
            },
            {
                "name": "Waist Band",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.waist_band
            }
        ],
        "Nightwear & Loungewear": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Wash Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.wash_type
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Fit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fit
            },
            {
                "name": "Collar",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.collar
            },
            {
                "name": "Neck",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.neck
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleeve Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sleeve_length
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Watches": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Strap Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.strap_material
            },
            {
                "name": "Water Resistant",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Display",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.display
            },
            {
                "name": "Glass Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.glass_material
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Power Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.power_type
            },
            {
                "name": "Battery Life",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Bluetooth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Call Function",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Heart Rate Monitor",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Pedometer",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sleep Monitor",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "SPO2 Monitor",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Warranty",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Buckle Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.buckle_material
            },
            {
                "name": "Buckle Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.buckle_type
            },
            {
                "name": "Dial Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.dial_shape
            }
        ],
        "Gloves": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Socks": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Socks Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.socks_length
            }
        ],
        "Stockings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Laces": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Soles & Charms": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Shoe Racks & Organisers": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Shoe Care - Accessories": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Flip-Flops & Flats": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Sandals & Floaters": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Backpacks": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Water Resistant",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Backpack Style",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.backpack_style
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Laptop Compartment",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Strap Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.strap_type
            },
            {
                "name": "Volume",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lock Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lock_type
            }
        ],
        "Handbags": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Water Resistant",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Laptop Compartment",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Strap Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.strap_type
            },
            {
                "name": "Volume",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lock Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lock_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Trolley, Luggage & Suitcases": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Water Resistant",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lining",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Num Pockets",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Laptop Compartment",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Strap Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.strap_type
            },
            {
                "name": "Volume",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lock Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lock_type
            }
        ],
        "Formal Shoes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Casual Shoes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Sports Shoes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Sport Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sport_type
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Outdoor Shoes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Work & Safety Shoes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Ethnic Shoes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Boots": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Heels": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Size Chart",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fabric",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Fabric Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fabric_finish
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Footwear Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.footwear_type
            },
            {
                "name": "Insole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.insole
            },
            {
                "name": "Sole Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.sole_material
            },
            {
                "name": "Toe Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.toe_shape
            },
            {
                "name": "Outsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.outsole
            },
            {
                "name": "Fasten Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.fasten_type
            },
            {
                "name": "Midsole",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.midsole
            }
        ],
        "Contact Lenses": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Lens Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_material
            },
            {
                "name": "Lens Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_colour
            }
        ],
        "Eye Glasses": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Frame Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_type
            },
            {
                "name": "Frame Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_shape
            },
            {
                "name": "Frame Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_colour
            },
            {
                "name": "Frame Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_size
            },
            {
                "name": "Frame Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_material
            },
            {
                "name": "Frame Style",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_style
            },
            {
                "name": "Face Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.face_shape
            },
            {
                "name": "Lens Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_material
            },
            {
                "name": "Lens Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_colour
            }
        ],
        "Eye Glass Frames": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Frame Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_type
            },
            {
                "name": "Frame Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_shape
            },
            {
                "name": "Frame Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_colour
            },
            {
                "name": "Frame Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_size
            },
            {
                "name": "Frame Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_material
            },
            {
                "name": "Frame Style",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_style
            },
            {
                "name": "Face Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.face_shape
            },
            {
                "name": "Lens Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_material
            },
            {
                "name": "Lens Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_colour
            }
        ],
        "Sunglasses": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Frame Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_type
            },
            {
                "name": "Frame Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_shape
            },
            {
                "name": "Frame Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_colour
            },
            {
                "name": "Frame Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_size
            },
            {
                "name": "Frame Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_material
            },
            {
                "name": "Frame Style",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.frame_style
            },
            {
                "name": "Face Shape",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.face_shape
            },
            {
                "name": "Lens Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_material
            },
            {
                "name": "Lens Colour",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.lens_colour
            }
        ],
        "Contact Lens Cases": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Contact Lens Solutions": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Contact Lens Tweezers": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Eyeglasses Pouches & Cases": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Microfiber Wipes": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Eyewear Slings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Bracelets": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender,
                "disabled":true,
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors,
                "disabled":true,
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal,
                "disabled":true,
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating,
                "disabled":true,
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Chains": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Mangalsutra": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Anklets": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Bangles & Bracelets": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Necklaces": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Earrings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Jewellery Sets": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Nosepins & Noserings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Pendants": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Rings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Toe Rings": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Gold Coins": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Brooch": [
            {
                "name": "Gender",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gender
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input",
            },
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors.map(color=>({...color, value:color.key}))
            },
            {
                "name": "Base Metal",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.base_metal
            },
            {
                "name": "Plating",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.plating
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.features
            },
            {
                "name": "Material",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material
            },
            {
                "name": "Material Finish",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.material_finish
            },
            {
                "name": "Pattern",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.pattern
            },
            {
                "name": "Occasion",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.occasion
            },
            {
                "name": "Season",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.season
            },
            {
                "name": "Trend",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.trend
            },
            {
                "name": "Bundles",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Max Sale Quantity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Fragile",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Liquid",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Hazardous",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Closure Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.closure_type
            },
            {
                "name": "Stone Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.stone_type
            },
            {
                "name": "Gem Type",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.gem_type
            },
            {
                "name": "Sustainability",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Handcrafted",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Craftmark",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ]
    },
    Appliances: {
        "Audio": [],
        "Camera and Camcorder": [],
        "Safety Security": [],
        "Speaker": [],
        "Television": [],
        "Video": [],
        "Air Conditioning and Air Cleaners": [],
        "Health, Home and Personal Care": [],
        "Heaters": [],
        "Kitchen Appliances": [],
        "Lighting & Electric Fans": [],
        "Refrigerators and Freezers": [],
        "Vacuum Cleaners": [],
        "Washing Machines and Accessories": [],
        "Water Purifiers and Coolers": [],
        "Inverter & Stabilizer": []
    },
    Electronics: {
        "Mobile Phone": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ram_unit
            },
            {
                "name": "ROM",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "ROM unit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.rom_unit
            },
            {
                "name": "Storage",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Storage unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_unit
            },
            {
                "name": "Screen Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Primary Camera",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Secondary Camera",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "CPU",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "GPU",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Battery Capacity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Version",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Connectivity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.connectivity
            },
            {
                "name": "Form factor",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Smart Watch": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Screen Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Headset": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Connectivity",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.connectivity
            },
            {
                "name": "Form factor",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Laptop": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ram_unit
            },
            {
                "name": "ROM",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "ROM unit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.rom_unit
            },
            {
                "name": "Storage",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Storage unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_unit
            },
            {
                "name": "Storage Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_type
            },
            {
                "name": "Screen Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "CPU",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "GPU",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Battery Capacity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Version",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Includes",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Desktop": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ram_unit
            },
            {
                "name": "ROM",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "ROM unit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.rom_unit
            },
            {
                "name": "Storage",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Storage unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_unit
            },
            {
                "name": "Storage Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_type
            },
            {
                "name": "Screen Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "CPU",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "GPU",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Battery Capacity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Version",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Form factor",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Includes",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Tablet": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "RAM unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.ram_unit
            },
            {
                "name": "ROM",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "ROM unit",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.rom_unit
            },
            {
                "name": "Storage",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Storage unit",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_unit
            },
            {
                "name": "Storage Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.storage_type
            },
            {
                "name": "Screen Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "CPU",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "GPU",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Battery Capacity",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Type",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "OS Version",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Includes",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Keyboard": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Connectivity",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.connectivity
            },
            {
                "name": "Compatible Devices",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.compatible_devices
            },
            {
                "name": "Includes",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Monitor": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Screen Size",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Feature",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.special_feature
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Mouse": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": colors
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Connectivity",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.connectivity
            },
            {
                "name": "Special Feature",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": propertyEnums.special_feature
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Power Bank": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model Year",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Refurbished",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ]
    },
    Grocery: {
        "Fruits and Vegetables": [],
        "Masala & Seasoning": [],
        "Oil & Ghee": [],
        "Eggs, Meat & Fish": [],
        "Cleaning & Household": [],
        "Bakery, Cakes & Dairy": [],
        "Pet Care": [],
        "Stationery": [],
        "Dairy and Cheese": [],
        "Snacks, Dry Fruits, Nuts": [],
        "Pasta, Soup and Noodles": [],
        "Cereals and Breakfast": [],
        "Sauces, Spreads and Dips": [],
        "Chocolates and Biscuits": [],
        "Cooking and Baking Needs": [],
        "Tinned and Processed Food": [],
        "Atta, Flours and Sooji": [],
        "Rice and Rice Products": [],
        "Dals and Pulses": [],
        "Salt, Sugar and Jaggery": [],
        "Energy and Soft Drinks": [],
        "Water": [],
        "Tea and Coffee": [],
        "Fruit Juices and Fruit Drinks": [],
        "Snacks and Namkeen": [],
        "Ready to Cook and Eat": [],
        "Pickles and Chutney": [],
        "Indian Sweets": [],
        "Frozen Vegetables": [],
        "Frozen Snacks": [],
        "Gift Voucher": []
    },
    "Home & Kitchen": {
        "Home Decor": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Furniture": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Home Furnishing - Bedding and Linen": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Cleaning Supplies": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Electricals": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Bathroom and Kitchen fixtures": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Garden & Outdoor": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Sports and Fitness Equipment": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Cookware": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Serveware": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Kitchen Storage and Containers": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Kitchen Tools": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Closet/Laundry/Shoe Organization": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Toys and Games": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Stationery": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Colour",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/colour**"
            },
            {
                "name": "Colour Name",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Material",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/material**"
            },
            {
                "name": "Size",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Weight",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Length",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Breadth",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Height",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Model",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Assembly Required",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Care Instructions",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            },
            {
                "name": "Special Features",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Gift Voucher": []
    },
    "Health & Wellness": {
        "Pain Relief": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Nutrition and Fitness Supplements": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Speciality Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Covid Essentials": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Diabetes Control": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Healthcare & Fitness Devices": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Ayurvedic": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Homeopathy": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Unani and Siddha": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Elder Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Baby Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Orthopaedic Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Mobility Aids": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Medicated Hair Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Medicated Skin Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Speciality Face Cleansers": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Gastric Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "ENT Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Eye Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Cold and Cough": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Sexual Wellness": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Feminine Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Maternity Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Nursing and Feeding": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Hand Wash": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Sanitizers": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Baby Care - Wipes and Buds": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Baby Care - Rash Creams": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Baby Care - Diapers and Accessories": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Health and Safety": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Oral Care": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Contraceptives": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Breathe Easy": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Health Foods and Drinks": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Wound Care and Dressings": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Surgicals": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Mental Wellness": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ],
        "Gift Voucher": [
            {
                "name": "Brand",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/brand**"
            },
            {
                "name": "Prescription Required",
                "required": true,
                "example": "",
                "variationAllowed": false,
                "type": "select",
                "options": "propertyEnums/prescription_required**"
            },
            {
                "name": "Usage Instruction",
                "required": false,
                "example": "",
                "variationAllowed": false,
                "type": "input"
            }
        ]
    },
    "Beauty & Personal Care": {
        Fragrance: [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Bath Soaps and Gels": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Hair Oils, Care, and Styling": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Shampoos and Conditioners": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Shaving and Grooming": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Beard Care and Tools": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Grooming Tools and Accessories": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Nail Care": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Eyes": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Face": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Lips": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Body": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Remover": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Sets and Kits": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Tools and Brushes": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Makeup - Kits and Combos": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Skin Care - Face Cleansers": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Skin Care - Hand and Feet": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Body Care - Cleansers": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Body Care - Moisturizers": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Body Care - Loofah and Other Tools": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Body Care - Bath Salt and Additives": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Hair Care - Shampoo, Oils, Conditioners": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Skin Care - Lotions, Moisturisers, and Creams": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Skin Care - Oils and Serums": [
            {
                name: "Brand",
                required: true,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Colour",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: colors,
            },
            {
                name: "Colour Name",
                required: false,
                example: "",
                variationAllowed: false,
                type: "input",
            },
            {
                name: "Gender",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.gender,
            },
            {
                name: "Concern",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.concern,
            },
            {
                name: "Ingredient",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.ingredient,
            },
            {
                name: "Conscious",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.conscious,
            },
            {
                name: "Preference",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.preference,
            },
            {
                name: "Formulation",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.formulation,
            },
            {
                name: "Skin Type",
                required: false,
                example: "",
                variationAllowed: false,
                type: "select",
                options: propertyEnums.skin_type,
            },
        ],
        "Gift Voucher": [],
    },
    "F&B": {
        default: [],
    },
};
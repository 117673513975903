import React, { useEffect, useRef, useState, useMemo } from "react";
import cogoToast from "cogo-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";
import {Checkbox, TextField, FormControl, FormLabel, FormHelperText} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import DeleteIcon from "@mui/icons-material/Delete";
import RenderInput from "../../../../utils/RenderInput";
import GemstoneField from "../gemstonefields";
import GenerateNamePopupFields from "../GenerateNamePopup";
import GenerateDescPopupFields from "../GenerateDescPopup";
import { _goldFields, _diamondFields, _makeToOrderFields, _silverFields, gemStone, _nonFashionFields, _fashionFields } from "../product-fields-modified";
import {
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
} from "../../../../utils/constants";
import { getCall } from "../../../../Api/axios";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { _productFields, _variationFields } from "../product-fields-modified";
import { calculateGemstonePrice, calculateMakeToOrderMaxPrice, calculateMakeToOrderMaxSellingPrice, calculateSellerPrice, calculateTotalSellingPrice, getProductFieldDetails, hsn_gst_mapping } from "../utils";

const ProductInfoForm = ({
  productId,
  productInfoForm,
  category,
  subCategory,
  jewelleryType,
  setFocusedField,
  variationOn,
  goldRate,
  silverRate,
  duplicateProductCode,
  handleDuplicateProductCode,
  isAdmin,
  makeToOrder,
  HSNCode,
  isHSNCode
}) => {
  const { formValues, setFormValues, errors } = productInfoForm;
  const [showDialog, setShowDialog] = useState(false);
  const [isMakingChargesInPercent, setIsMakingChargesInPercent] = useState();
  const [showGenerateNameDialog, setShowGenerateNameDialog] = useState(false);
  const [showGenerateDescDialog, setShowGenerateDescDialog] = useState(false);
  const [newGemstone, setNewGemstone] = useState({
    gemstoneType: "",
    gemstoneSize: "",
    gemstoneCount: "",
    gemstoneCostPerStone: "",
  });
  const [autofillChecked, setAutoFillChecked] = useState(false);
  const theme = useTheme();

  //calculate prices whenever there is change in formvalues
  const gemstonePrice = useMemo(
    () => calculateGemstonePrice(formValues),
    [formValues]
  );
  const sellerPrice = useMemo(
    () => calculateSellerPrice({updatedValues: formValues, goldRate, silverRate, jewelleryType, sellerBasePrice:formValues.sellerBasePrice}),
    [formValues, goldRate, silverRate]
  );
  const totalSellingPrice = useMemo(
    () => calculateTotalSellingPrice({updatedValues: formValues, goldRate, silverRate, jewelleryType, sellerPrice}),
    [formValues, goldRate, silverRate, sellerPrice]
  );
  const makeToOrderMaxPrice = useMemo(
    () => calculateSellerPrice({updatedValues: formValues, goldRate, silverRate, jewelleryType, sellerBasePrice:formValues.sellerBasePrice, sellerPrice, makeToOrder:true}),
    [formValues, goldRate, silverRate, sellerPrice]
  );
  const makeToOrderMaxSellingPrice = useMemo(
    () => calculateTotalSellingPrice({updatedValues: formValues, goldRate, silverRate, jewelleryType, makeToOrderMaxPrice, makeToOrder:true}),
    [formValues, goldRate, silverRate]
  );

  //initialize product fields based on jewelleryType by first determining exclusion fields
  let exclusionFields =
    jewelleryType === "fashion" && makeToOrder
      ? _makeToOrderFields.filter(m => m !== "makeToOrderDispatchTime").concat(_diamondFields, _silverFields, _nonFashionFields)
      : jewelleryType === "fashion"
        ? _diamondFields.concat(_silverFields, _nonFashionFields)
        : jewelleryType === "gold"
          ? _diamondFields.concat(_silverFields, _fashionFields)
          : jewelleryType === "diamond"
            ? _silverFields.concat(_fashionFields)
            : jewelleryType === "silver"
              ? _diamondFields.concat(_goldFields, _fashionFields)
              : [];

  let product_fields = _productFields.filter(field => !exclusionFields.includes(field));

  //update product fields for variants
  product_fields = 
    variationOn === "attributes"
      ? product_fields.filter(field=>!_variationFields.includes(field))
      : product_fields
  //update product fields for make to order
  product_fields =
    makeToOrder
      ? product_fields
      : product_fields.filter(field=>!_makeToOrderFields.includes(field))

  const field_details = product_fields.map(fieldId=>getProductFieldDetails(fieldId));

  //update initial Purity value for jewelleryType = silver
  useEffect(()=>{
    if(jewelleryType === "silver"){
      setFormValues((prevValues) => {
        const newValues = {...prevValues};
        if(!newValues.Purity){
          newValues.Purity = "92.5"
        }
        return newValues;
      });
    }
  }, []);

  useEffect(()=>{
    if(formValues.makingChargesType==="percentage"){
      setIsMakingChargesInPercent(true);
    } else if(formValues.makingChargesType==="amount"){
      setIsMakingChargesInPercent(false);
    }
  }, [formValues.makingChargesType]);

  useEffect(()=>{
    if(!HSNCode){
      return;
    }
    const gst = hsn_gst_mapping[HSNCode];
    if(gst){
      setFormValues((prevValues) => ({
        ...prevValues,
        GST_Percentage: gst,
      }));
    }
  }, [HSNCode]);

  useEffect(()=>{
    if(formValues.productName!==formValues.description)
      setAutoFillChecked(false)
  }, [formValues.description]);

  //update desc when changes in name occurs
  useEffect(()=>{
    if(autofillChecked)
      setFormValues({
        ...formValues,
        description: formValues.productName
      })
  }, [formValues.productName]);

  //update total prices when changes in formValues take place
  useEffect(()=>{
    setFormValues((prevValues) => ({
      ...prevValues,
      gemstonePrice: gemstonePrice?.toFixed(2),
      totalSellingPrice: totalSellingPrice?.toFixed(2),
      sellerPrice: sellerPrice?.toFixed(2),
      makeToOrderMaxPrice: makeToOrderMaxPrice?.toFixed(2),
      makeToOrderMaxSellingPrice: makeToOrderMaxSellingPrice?.toFixed(2),
    }));
  }, [gemstonePrice, totalSellingPrice, sellerPrice, makeToOrderMaxPrice, makeToOrderMaxSellingPrice])

  const handleGemstoneChange = (updatedGemstone) => {
    // Update the local state for the current gemstone
    setNewGemstone((prevGemstone) => ({
      ...prevGemstone,
      ...updatedGemstone,
    }));
  };

  const handleDialogSubmit = () => {
    // Add the gemstone to the form state only if it has valid values
    if (
      newGemstone.gemstoneType &&
      newGemstone.gemstoneCount &&
      newGemstone.gemstoneCostPerStone
    ) {
      // Update the gemstone values in the gemstone array in the parent component
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        gemstones: [...(prevFormValues?.gemstones || []), newGemstone],
      }));

      setNewGemstone({
        gemstoneType: "",
        gemstoneSize: "",
        gemstoneCount: "",
        gemstoneCostPerStone: "",
      });

      setShowDialog(false);
    } else {
      cogoToast.error("Please fill out all the required fields");
    }
  };

  const handleDialogCancel = () => {
    setShowDialog(false);
  };
  const handleGenerateNameDialogCancel = () => {
    setShowGenerateNameDialog(false);
  };
  const handleGenerateDescDialogCancel = () => {
    setShowGenerateDescDialog(false);
  };
  const handleGemstoneDelete = (index) => {
    const updatedGemstones = [...formValues.gemstones];
    updatedGemstones.splice(index, 1);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      gemstones: updatedGemstones,
    }));
  };
  let priceHeadingBool = 1;

  return (
    <>
    <h1 className="py-2 text-2xl font-semibold">Basic Product Details:</h1>
      {field_details?.map((item, index) => {
        // let item = getProductFieldDetails(fieldId);
        let returnElement = true;
        if (subCategory) {
          const subCatList = PRODUCT_SUBCATEGORY[category];
          const selectedSubCatObject = subCatList?.find(
            (subitem) => subitem.value === subCategory
          );
          if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
            const hiddenFields =
              FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
                selectedSubCatObject.protocolKey
              ];
            const fielditemAvailableInHidden = hiddenFields.find(
              (hiddenItem) => hiddenItem === item.id
            );
            if (fielditemAvailableInHidden) {
              returnElement = false;
            }
          }
        } else {
        }
        if (returnElement) {
          let renderElement = <RenderInput
              key={item.id}
              previewOnly={
                productId && item.id === "productCode" ? true : false
              }
              item={{
                ...item,
                error: errors?.[item.id] ? true : false,
                helperText: errors?.[item.id] || "",
              }}
              state={formValues}
              stateHandler={setFormValues}
              setFocusedField={setFocusedField}
            />;
          switch (item.id) {
            case "makeToOrder":
              renderElement = (
                <div key={item.id}>
                  {renderElement}
                  {makeToOrder &&
                    <p className="ml-2 mb-2 text-sm font-serif text-gray-400 cursor-default">
                      {">>>Please update the dispatch time in the Delivery Info tab"}
                    </p>}
                </div>
              )
              break;
            case "productCode":
              item.handleBlur = async() => {
                const checkUrl = `/api/v1/checkProductCode?productCode=${formValues.productCode}`;
                const res = await getCall(checkUrl);
                if(res?.duplicateProductCode === true){
                  handleDuplicateProductCode(true);
                } else {
                  handleDuplicateProductCode(false);
                }
              }
              renderElement = (
                <div key={item.id}>
                  {renderElement}
                  {duplicateProductCode && !errors.productCode &&
                    <p className="ml-2 mb-2 text-xs text-yellow-500 cursor-default">
                      Warning: A product with the same number already exists!
                    </p>}
                </div>
              )
              break;
            case "productName":
              renderElement = (
                <div key={item.id}>
                  {renderElement}
                  {isAdmin && <button 
                    type="button"
                    className="border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                    onClick={()=>{
                      setShowGenerateNameDialog(true);
                    }}
                  >
                    <AutoFixHighIcon />
                    &nbsp;
                    Generate Name
                  </button>}
                </div>
              )
              break;
            case "diamonds_weight":
              renderElement = (
              <div key={item.id}>
                <label className="text-2xl font-semibold mb-4 mt-14">Diamond Details:</label>
                {renderElement}
              </div>
              )
              break;
            case "wastageOnGold":
            case "diamondCostPerCt":
              if(priceHeadingBool){
                priceHeadingBool = 0;
                renderElement = (
                  <div key={item.id}>
                    <label className="text-2xl font-semibold mb-4 mt-14">Pricing:</label>
                    {renderElement}
                  </div>
                )
              }
              break;
              case "description":
                renderElement = (
                <div key={item.id}>
                  {renderElement}
                  {isAdmin && <button 
                    type="button"
                    className="border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                    onClick={()=>{
                      setShowGenerateDescDialog(true);
                    }}
                  >
                    <AutoFixHighIcon />
                    &nbsp;
                    Generate Description
                  </button>}
                  <div>
                    <Checkbox
                      type="checkbox"
                      id="copy-checkbox"
                      className="!scale-75"
                      checked={autofillChecked}
                      onChange={(e)=>{
                        if(e.target.checked)
                          setFormValues({
                        ...formValues,
                        description: formValues.productName
                          })
                        setAutoFillChecked(e.target.checked)
                      }}
                      >
                    </Checkbox>
                    <label htmlFor="copy-checkbox" className="text-xs py-2 font-medium text-left text-[#606161]">AutoFill from Product Name?</label>
                    {autofillChecked && <p className="mt-[-8px] font-serif text-sm text-gray-400 cursor-default">
                        &gt;&gt;&gt;We'll take care of this, for now ;&#41;</p>}
                  </div>
                </div>
              )
              break;
              case "net_weight_range":
                renderElement = (
                  <div key={item.id}>
                    {renderElement}
                    <p className="font-serif text-sm text-gray-400 cursor-default">
                        &gt;&gt;&gt;Enter Min Weight and Max Weight for this item when made-to-order</p>
                  </div>
                )
                break;
              case "Finishing":
                renderElement =
                  jewelleryType === "silver"
                    ? (
                  <div key={item.id}>
                    <RenderInput
                      key={item.id}
                      previewOnly={
                        productId && item.id === "productCode" ? true : false
                      }
                      item={{
                        ...item,
                        error: errors?.[item.id] ? true : false,
                        helperText: errors?.[item.id] || "",
                        options: [
                          { key: "Silver", value: "silver" },
                          { key: "Yellow Gold", value: "yellow" },
                          { key: "Rose Gold", value: "rose" },
                        ]
                      }}
                      state={formValues}
                      stateHandler={setFormValues}
                      setFocusedField={setFocusedField}
                    />
                  </div>
                ) : renderElement;
                break;
              case "Purity":
                renderElement =
                  jewelleryType === "silver"
                    ? (
                  <div key={item.id}>
                    <RenderInput
                      key={item.id}
                      previewOnly={
                        productId && item.id === "productCode" ? true : false
                      }
                      item={{
                        ...item,
                        error: errors?.[item.id] ? true : false,
                        helperText: errors?.[item.id] || "",
                        options: [
                          { key: "99.9 (Fine Silver)", value: "99.9" },
                          { key: "99.0", value: "99.0" },
                          { key: "97.0", value: "97.0" },
                          { key: "92.5 (Sterling Silver)", value: "92.5" },
                          { key: "90.0", value: "90.0" },
                          { key: "83.5", value: "83.5" },
                          { key: "80.0", value: "80.0" },
                        ]
                      }}
                      state={formValues}
                      stateHandler={setFormValues}
                      setFocusedField={setFocusedField}
                    />
                  </div>
                ) : renderElement;
                break;
              case "makingChargesType":
                renderElement =
                  jewelleryType === "silver"
                    ? (
                  <div key={item.id} className="mt-4">
                    <label className="text-2xl font-semibold">Pricing:</label>
                    {renderElement}
                  </div>
                ) : renderElement;
                break;
              case "makingCharges":
                renderElement =
                  isMakingChargesInPercent===true
                    ? (
                  <div key={item.id}>
                    <RenderInput
                      key={item.id}
                      previewOnly={
                        productId && item.id === "productCode" ? true : false
                      }
                      item={{
                        ...item,
                        error: errors?.[item.id] ? true : false,
                        helperText: errors?.[item.id] || "",
                        title: "Making Charges (in %)",
                        placeholder: "E.g., 10%",
                        isPercentage: true,
                      }}
                      state={formValues}
                      stateHandler={setFormValues}
                      setFocusedField={setFocusedField}
                    />
                  </div>
                ) : renderElement;
                break;
              case "gemstonePrice":
                renderElement = (
                  <div key={item.id}>
                    <button
                          type="button"
                          className={
                            "border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                          }
                          onClick={() => setShowDialog(true)}
                        >
                          Add Gemstone
                    </button>
                    {formValues.gemstones?.length>0 ? <div className="mt-2">
                      <h2 className="font-semibold">Added Gemstones:</h2>
                      <ul>
                        {formValues?.gemstones?.map((gemstone, index) => (
                          <li key={index} className="ml-8">
                          {gemstone && (
                            <div key={item.id}>
                              <strong>Type:</strong> {gemstone.gemstoneType}, <strong>Count:</strong> {gemstone.gemstoneCount}, <strong>Cost per Stone:</strong> {gemstone.gemstoneCostPerStone}
                            </div>
                          )}
                          <button type="button" onClick={() => handleGemstoneDelete(index)} className="ml-2">
                            <DeleteIcon />
                          </button>
                        </li>
                        ))}
                      </ul>
                    </div> : <p className="mt-2 font-serif text-sm text-gray-400 cursor-default">
                        &lt;&lt;&lt;No gemstones added yet. Start by adding your first gemstone!&gt;&gt;&gt;</p>}
                    {renderElement}
                  </div>
                )
                break;
              case "GST_Percentage":
                isHSNCode
                  ? item.isDisabled = true
                  : hsn_gst_mapping[HSNCode]
                    ? item.isDisabled = true
                    : item.isDisabled = false
              break;
              case "totalSellingPrice":
                if(!isAdmin)
                renderElement = <div key={item.id}></div>
              break;
              case "makeToOrderMaxSellingPrice":
                if(!isAdmin)
                renderElement = <div key={item.id}></div>
              break;
            case "sellerBasePrice":
              item.title = isAdmin ? "Seller Price (excluding GST)" : item.title;
              renderElement = 
                jewelleryType === "fashion"
                  ? (
                <div key={item.id} className="mt-4">
                  <label className="text-2xl font-semibold">Pricing:</label>
                  {renderElement}
                </div>
              ) : renderElement;
              break;
            case "sellerPrice":
              jewelleryType === "fashion"
                ? item.title = isAdmin ? "Seller Price (including GST)" : item.title
                : item.title = isAdmin ? "Seller Price (Auto Calculated)" : item.title;
              break;
          }
          return renderElement;
        } else {
          return <div key={item.id}></div>
        }
      })}
      {/* <Button variant="outlined" style={{borderColor:"#b47437", margin:"4px 0"}} onClick={() => setShowDialog(true)}>
        <span style={{ fontWeight:"900", fontSize:"16px", fontFamily:"serif", color:"#b47437"}}>Add Gemstones</span>
      </Button> */}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <GemstoneField
            gemstone={newGemstone}
            onChange={handleGemstoneChange}
            gemstoneFields={gemStone}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} className="!text-[#b47437]">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} className="!text-[#b47437]">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showGenerateNameDialog} onClose={() => setShowGenerateNameDialog(false)}>
        <DialogContent>
          <GenerateNamePopupFields
            state={formValues}
            statehandler={setFormValues}
          /> 
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleGenerateNameDialogCancel} className="!text-[#b47437]">
            Submit
          </Button> */}
          <Button onClick={handleGenerateNameDialogCancel} className="!text-[#b47437]">
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showGenerateDescDialog} onClose={() => setShowGenerateDescDialog(false)}>
        <DialogContent>
          <GenerateDescPopupFields
            state={formValues}
            statehandler={setFormValues}
          /> 
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleGenerateDescDialogCancel} className="!text-[#b47437]">
            Submit
          </Button> */}
          <Button onClick={handleGenerateDescDialogCancel} className="!text-[#b47437]">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductInfoForm;
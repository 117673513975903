import { useEffect, useState } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import MyButton from "../../Shared/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useForm from "../../../hooks/useForm";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { allProductFieldDetails } from "./product-fields-modified";
// import AddGenericProduct from "./ProductDetailsSellers";
import { PRODUCT_SUBCATEGORY } from "../../../utils/constants";
import { getCall } from "../../../Api/axios";
import ProductDetails from "./ProductDetails";
import CategoryInfoForm from "./forms/CategoryInfoForm";
import { categoryInitialValues } from "./utils";
import cogoToast from "cogo-toast";

export default function AddProduct() {
  const navigate = useNavigate();
  const [fields, setFields] = useState(allProductFieldDetails);
  const [attributes, setAttributes] = useState([]);
  const [variants, setVariants] = useState([""]);
  const [variantsCheckboxState, setVariantsCheckboxState] = useState({});
  const [renderCategories, setRenderCategories] = useState(true);
  const [variationOn, setVariationOn] = useState("none");
  const [providerDetails, setProviderDetails] = useState();
  const [disableNext, setDisableNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();

  const categoryForm = useForm(categoryInitialValues);
  console.log("categoryForm", categoryForm);

  const getOrgDetails = async (org_id) => {
    const url = `/api/v1/organizations/${org_id}`;
    const res = await getCall(url);
    return res;
  };

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    return res[0];
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    categoryForm.setFormValues((prev) => {
      return { ...prev, productCategory: "Fashion" };
    });
    let data = [...fields]; // Create a copy of the fields array
    const subCategoryIndex = data.findIndex(
      (item) => item.id === "productSubcategory1"
    );
    //Note:- This is where we are setting the options for the productSubcategory1 field, data is a shallow copy of allProductFieldDetails,
    //       so changing data.options will change the options in allProductFieldDetails as well.
    data[subCategoryIndex].options = PRODUCT_SUBCATEGORY["Fashion"];
    // console.log(data[subCategoryIndex].options);
    setFields(data);
    getUser(user_id).then((u) => {
      getOrgDetails(u.organization).then((org) => {
        console.log("org details->", org);
        let category = org?.providerDetail?.storeDetails?.category;
        if (!category){
          cogoToast.error("Please update your store's product category first");
          navigate(`/application/store-details/${u.organization}`);
        };
        setProviderDetails(org?.providerDetail);
      });
    });
  }, []);

  useEffect(()=>{
    if(providerDetails){
      setIsLoading(false);
      setDisableNext(false);
    }
  }, [providerDetails]);

  const getSelectedVariantNames = () => {
    let variant_names = Object.keys(variantsCheckboxState);
    return variant_names.filter(
      (variant_name) => variantsCheckboxState[variant_name]
    );
  };

  const anyVariantSelected = () => {
    let variant_names = Object.keys(variantsCheckboxState);
    return variant_names.some(
      (variant_name) => variantsCheckboxState[variant_name]
    );
  };

  const renderForms = () => {
    if (renderCategories || isLoading) {
      return (
        <CategoryInfoForm
          categoryForm={categoryForm}
          variants={variants}
          setVariants={setVariants}
          setAttributes={setAttributes}
          variationOn={variationOn}
          setVariationOn={setVariationOn}
          variantsCheckboxState={variantsCheckboxState}
          setVariantsCheckboxState={setVariantsCheckboxState}
        />
      );
    } else {
      let selectedCategory = categoryForm.formValues?.productCategory;
      return (
        <ProductDetails
          categoryForm={categoryForm}
          category={selectedCategory}
          subCategory={categoryForm.formValues?.productSubcategory1}
          attributes={attributes}
          variants={variants}
          variationOn={variationOn}
          providerDetails={providerDetails}
          jewelleryType={categoryForm.formValues?.jewelleryType}
          jewelleryCategory={categoryForm.formValues?.jewelleryCategory}
          selectedVariantNames={getSelectedVariantNames()}
        />
      );
    }
  };

  return (
    <>
      <div className="container mx-auto my-8 px-2 md:px-8 pb-6">
        <div
          className="w-full bg-white px-4 py-4 rounded-md h-full scrollbar-hidden"
          style={{ minHeight: "95%", maxHeight: "100%", overflow: "auto" }}
        >
          <BackNavigationButton onClick={() => navigate(-1)} />
          <div className="w-full !h-full">
            <label className="ml-2 md:mb-4 md:mt-3 mt-2 font-semibold text-xl">
              {renderCategories ? "Product Type & Category" : "Add Product"}
            </label>
            <div className="mt-2">{renderForms()}</div>
            <div className="flex flex-row justify-center items-center pt-3 gap-4">
              {/* TODO: Make the cancel button less prominent than the "NEXT" and subsequent Add/Update Products button */}
              <MyButton
                type="button"
                title="CANCEL"
                size="small"
                className="!text-black !w-20 !bg-[#ebe4dc]"
                variant="contained"
                onClick={() => navigate(-1)}
              />
              {(renderCategories || isLoading) && (
                <MyButton
                  type="button"
                  title={
                    <div className="flex items-center justify-center">
                      {isLoading && !renderCategories ? (
                        <>
                          NEXT&nbsp;&nbsp;
                          <CircularProgress size={18} sx={{ color: theme.palette.tertiary.main }} />
                        </>
                      ) : (
                        <span>
                          NEXT
                        </span>
                      )}
                    </div>
                  }
                  size="small"
                  className="!text-black !w-20"
                  variant="contained"
                  disabled={
                    !(
                      categoryForm.formValues["productCategory"] &&
                      categoryForm.formValues["productSubcategory1"] &&
                      categoryForm.formValues["jewelleryType"] &&
                      categoryForm.formValues["jewelleryCategory"] &&
                      (variationOn === "none" ||
                        variationOn === "uom" ||
                        anyVariantSelected())
                    ) || disableNext
                  }
                  onClick={() => {
                    isLoading && setDisableNext(true);
                    setRenderCategories(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

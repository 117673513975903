import {
  MAX_STRING_LENGTH,
  MAX_STRING_LENGTH_8,
  MAX_STRING_LENGTH_3,
  MAX_STRING_LENGTH_6,
  MAX_STRING_LENGTH_4096,
  MAX_STRING_LENGTH_13,
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
  MAX_STRING_LENGTH_100,
} from "../../../../utils/constants";
import { isAmountValid, isNumberOnly } from "../../../../utils/validations";
import { propertyEnums } from "../categoryProperties";
import { getFormErrors } from "../utils";

export const validateProductInfoForm = (
  formValues,
  jewelleryType,
  variationOn,
  makeToOrder
) => {
  let formErrors = {};
  formErrors.makeToOrder = !["true", "false"].includes(formValues?.makeToOrder)
    ? "Make to Order selection is required"
    : "";
  formErrors.productName =
    formValues?.productName?.trim() === ""
      ? "Product name is not allowed to be empty"
      : formValues?.productName?.length > MAX_STRING_LENGTH
      ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
      : "";
  // formErrors.HSNCode =
  //   formValues?.HSNCode?.trim() === ""
  //     ? "HSN code is not allowed to be empty"
  //     : formValues?.HSNCode?.length > MAX_STRING_LENGTH_8
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
  //     : "";
  // formErrors.huidNumber =
  //   formValues?.huidNumber?.trim() === "0"
  //     ? "HUID shoud be done."
  //     : formValues?.huidNumber?.trim() === ""
  //     ? `This field shoud not be empty`
  //     : "";

  formErrors.huidDone =
    ["silver", "fashion"].includes(jewelleryType)
      ? ""
      : formValues?.huidDone?.trim() === "false"
      ? "HUID should be done."
      : formValues?.huidDone?.trim() === ""
      ? "HUID Done is not allowed to be empty"
      : "";
  // formErrors.countryOfOrigin =
  //   formValues?.countryOfOrigin?.trim() === ""
  //     ? "Country of origin is not allowed to be empty"
  //     : "";
  formErrors.Finishing =
    ["fashion"].includes(jewelleryType)
      ? ""
      : formValues?.Finishing?.trim() === ""
          ? "Finishing is not allowed to be empty"
          : "";
  formErrors.GST_Percentage =
    formValues?.GST_Percentage === "" ? "GST percentage is required" : "";
  // formErrors.maxAllowedQty = !formValues?.maxAllowedQty
  //   ? "Please enter a valid Max. Allowed Quantity"
  //   : formValues?.maxAllowedQty?.length > MAX_STRING_LENGTH_10
  //   ? `Cannot be more than ${MAX_STRING_LENGTH_10} characters`
  //   : parseInt(formValues?.maxAllowedQty) > parseInt(formValues?.quantity)
  //   ? "Cannot be more than quantity"
  //   : "";
  // formErrors.UOM = formValues?.UOM === "" ? "UOM unit is required" : "";
  // formErrors.fulfillmentOption =
  //   formValues?.fulfillmentOption === undefined ||
  //   formValues?.fulfillmentOption === ""
  //     ? "Fulfillment Option is required"
  //     : "";
  // formErrors.UOM =
  //   formValues?.UOM?.trim() === ""
  //     ? "UOM is required"

  //     : formValues?.UOM?.length > MAX_STRING_LENGTH
  //     ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //     : "";
  // formErrors.length =
  //   formValues?.length?.trim() === ""
  //     ? "Length is required"
  //     : formValues?.length.length > MAX_STRING_LENGTH_6
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
  //     : "";
  // formErrors.breadth =
  //   formValues?.breadth?.trim() === ""
  //     ? "Breadth is required"
  //     : formValues?.breadth.length > MAX_STRING_LENGTH_6
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
  //     : "";
  // formErrors.height =
  //   formValues?.height?.trim() === ""
  //     ? "Height is required"
  //     : formValues?.height.length > MAX_STRING_LENGTH_6
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
  //     : "";

  formErrors.diamonds_weight = jewelleryType !== "diamond" ? "" :
      formValues?.diamonds_weight == "" ?
      "Diamonds Weight is required" :
      formValues?.diamonds_weight?.length > MAX_STRING_LENGTH_8 ?
      `Cannot be more than ${MAX_STRING_LENGTH_8} characters` :
      "";
  formErrors.diamondColour = jewelleryType !== "diamond" ? "" :
      formValues?.diamondColour?.trim() === "" ?
      "Diamond Colour is required" :
      formValues?.diamondColour?.length > MAX_STRING_LENGTH_3 ?
      `Cannot be more than ${MAX_STRING_LENGTH_3} characters` :
      "";
  formErrors.diamondClarity = jewelleryType !== "diamond" ? "" :
      formValues?.diamondClarity?.trim() === "" ?
      "Diamond Clarity is required" :
      formValues?.diamondClarity?.length > MAX_STRING_LENGTH_6 ?
      `Cannot be more than ${MAX_STRING_LENGTH_6} characters` :
      "";
  formErrors.certifications = jewelleryType !== "diamond" ? "" :
      formValues?.certifications !== "f_and_b" && formValues?.certifications?.length < 1 ?
      "Certification Image is required" :
      "";
  formErrors.diamondCostPerCt = jewelleryType !== "diamond" ? "" :
      Number.isNaN(parseFloat(formValues?.diamondCostPerCt)) ?
      "Please enter a valid number" :
      !isAmountValid(formValues?.diamondCostPerCt) ?
      "Please enter only digit" :
      "";
  formErrors.makingCharges =
    ["fashion"].includes(jewelleryType)
    ? ""
    : Number.isNaN(parseFloat(formValues?.makingCharges))
      ? "Please enter a valid number"
      : !isAmountValid(formValues?.makingCharges)
        ? "Please enter only digit"
        : formValues.makingChargesType === "percentage" && (formValues.makingCharges < 0 || formValues.makingCharges > 100)
          ? "Please enter a valid percentage between 0 and 100"
          : "";
  formErrors.Purity =
    ["fashion"].includes(jewelleryType)
      ? ""
      : formValues?.Purity?.trim() === "" ? "Purity is required" : "";
  // formErrors.dispatchTime =
  //   formValues?.dispatchTime?.trim() === ""
  //     ? "Dispatch Time is required"
  //     : !isNumberOnly(formValues?.returnWindow)
  //     ? "Please enter only digit"
  //     : formValues?.returnWindow?.length > MAX_STRING_LENGTH_3
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_3} characters`
  //     : "";
  formErrors.wastageOnGold =
    ["silver", "fashion"].includes(jewelleryType)
      ? ""
      : formValues?.wastageOnGold === ""
      ? "Please Enter Wastage of Gold Value (in %)"
      : formValues?.wastageOnGold > 100
      ? "Wastage on Gold Value (in %) should be less than 100"
      : "";
  // formErrors.returnWindow =
  //   formValues?.returnWindow?.trim() === ""
  //     ? "Return window is required"
  //     : !isNumberOnly(formValues?.returnWindow)
  //     ? "Please enter only digit"
  //     : formValues?.returnWindow?.length > MAX_STRING_LENGTH_3
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_3} characters`
  //     : "";
  // formErrors.manufacturerName =
  //   formValues?.manufacturerName?.trim() === ""
  //     ? "Manufacturer name is required"
  //     : formValues?.manufacturerName?.length > MAX_STRING_LENGTH_50
  //     ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
  //     : "";
  // formErrors.manufacturedDate =
  //   formValues?.manufacturedDate?.trim() === ""
  //     ? "Manufactured date is required"
  //     : "";
  // if (productInfoFields.includes("nutritionalInfo")) {
  //   formErrors.nutritionalInfo =
  //     formValues?.nutritionalInfo?.trim() === ""
  //       ? "Nutritional info is required"
  //       : formValues?.nutritionalInfo?.length > MAX_STRING_LENGTH
  //       ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //       : "";
  // }
  // if (productInfoFields.includes("additiveInfo")) {
  //   formErrors.additiveInfo =
  //     formValues?.additiveInfo?.trim() === ""
  //       ? "Additive info is required"
  //       : formValues?.additiveInfo?.length > MAX_STRING_LENGTH
  //       ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //       : "";
  // }
  // formErrors.instructions =
  //   formValues?.instructions?.trim() === ""
  //     ? "Instruction is required"
  //     : formValues?.instructions?.length > MAX_STRING_LENGTH
  //     ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //     : "";
  // formErrors.longDescription =
  //   formValues?.longDescription?.trim() === ""
  //     ? "Long description is required"
  //     : formValues?.longDescription?.length > MAX_STRING_LENGTH
  //     ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //     : "";
  formErrors.description =
    formValues?.description?.trim() === ""
      ? "Short description is required"
      : formValues?.description?.length > MAX_STRING_LENGTH_4096
      ? `Cannot be more than ${MAX_STRING_LENGTH_4096} characters`
      : "";
  // if (productInfoFields.includes("manufacturerOrPackerName")) {
  //   formErrors.manufacturerOrPackerName =
  //     formValues?.manufacturerOrPackerName?.trim() === ""
  //       ? "Manufacturer or packer name is required"
  //       : formValues?.manufacturerOrPackerName?.length > MAX_STRING_LENGTH_50
  //       ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
  //       : "";
  // }
  // if (productInfoFields.includes("manufacturerOrPackerAddress")) {
  //   formErrors.manufacturerOrPackerAddress =
  //     formValues?.manufacturerOrPackerAddress?.trim() === ""
  //       ? "Manufacturer or packer address is required"
  //       : formValues?.manufacturerOrPackerAddress?.length >
  //         MAX_STRING_LENGTH_100
  //       ? `Cannot be more than ${MAX_STRING_LENGTH_100} characters`
  //       : "";
  // }
  // if (productInfoFields.includes("commonOrGenericNameOfCommodity")) {
  //   formErrors.commonOrGenericNameOfCommodity =
  //     formValues?.commonOrGenericNameOfCommodity?.trim() === ""
  //       ? "Common or generic name of commodity is required"
  //       : formValues?.commonOrGenericNameOfCommodity?.length >
  //         MAX_STRING_LENGTH_50
  //       ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
  //       : "";
  // }
  // if (productInfoFields.includes("monthYearOfManufacturePackingImport")) {
  //   formErrors.monthYearOfManufacturePackingImport =
  //     formValues?.monthYearOfManufacturePackingImport?.trim() === ""
  //       ? "Month year of manufacture packing import is required"
  //       : formValues?.monthYearOfManufacturePackingImport?.length >
  //         MAX_STRING_LENGTH
  //       ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //       : "";
  // }
  // if (productInfoFields.includes("importerFSSAILicenseNo")) {
  //   formErrors.importerFSSAILicenseNo =
  //     formValues?.importerFSSAILicenseNo?.trim() === ""
  //       ? "Importer FSSAI license no is required"
  //       : !isNumberOnly(formValues?.importerFSSAILicenseNo)
  //       ? "Please enter only digit"
  //       : formValues?.importerFSSAILicenseNo?.length > MAX_STRING_LENGTH_14
  //       ? `Cannot be more than ${MAX_STRING_LENGTH_14} characters`
  //       : "";
  // }
  // if (productInfoFields.includes("brandOwnerFSSAILicenseNo")) {
  //   formErrors.brandOwnerFSSAILicenseNo =
  //     formValues?.brandOwnerFSSAILicenseNo?.trim() === ""
  //       ? "Brand owner FSSAI license no is required"
  //       : !isNumberOnly(formValues?.brandOwnerFSSAILicenseNo)
  //       ? "Please enter only digit"
  //       : formValues?.brandOwnerFSSAILicenseNo?.length > MAX_STRING_LENGTH_14
  //       ? `Cannot be more than ${MAX_STRING_LENGTH_14} characters`
  //       : "";
  // }
  formErrors.hallmarkDone =
    jewelleryType !== "silver"
      ? ""
      : formValues.hallmarkDone?.trim() === "false"
        ? "Hallmark shoud be done."
        : formValues.hallmarkDone?.trim() === ""
          ? "Hallmark Done is not allowed to be empty"
          : "";
  formErrors.makingChargesType =
    jewelleryType !== "silver"
      ? ""
      : formValues?.makingChargesType?.trim() === ""
        ? "Making Charges Type is required."
        : !["percentage", "amount"].includes(formValues?.makingChargesType?.trim())
          ? "Invalid Making Charges Type."
          : "";
  if (variationOn === "none") {
    formErrors.productCode =
      formValues?.productCode?.trim() === ""
        ? "Product code is not allowed to be empty"
        : !isNumberOnly(formValues?.productCode)
        ? "Please enter only digit"
        : formValues?.productCode?.length > MAX_STRING_LENGTH_13
        ? `Cannot be more than ${MAX_STRING_LENGTH_13} characters`
        : "";
    // formErrors.MRP = !formValues?.MRP
    //   ? "Please enter a valid number"
    //   : !isAmountValid(formValues?.MRP)
    //   ? "Please enter only digit"
    //   : "";
    // formErrors.purchasePrice = !formValues?.purchasePrice
    //   ? "Please enter a valid number"
    //   : !isAmountValid(formValues?.purchasePrice)
    //   ? "Please enter only digit"
    //   : "";
    formErrors.quantity = Number.isNaN(parseFloat(formValues?.quantity))
      ? "Please enter a valid Quantity"
      : +formValues.quantity < 0
      ? "Quantity cannot be negative"
      : ""
    formErrors.weight =
      ["fashion"].includes(jewelleryType)
      ? ""
      : formValues?.weight?.trim() === ""
        ? "Gross Weight is required"
        : formValues?.weight?.length > MAX_STRING_LENGTH_8
        ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
        : "";
    formErrors.net_weight =
      ["fashion"].includes(jewelleryType)
      ? ""
      : formValues?.net_weight?.trim() === ""
        ? "Net Weight is required"
        : formValues?.weight?.length > MAX_STRING_LENGTH_8
        ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
        : "";
    formErrors.net_weight_range =
      makeToOrder && !["fashion"].includes(jewelleryType) &&
      (!formValues?.net_weight_range ||
        (formValues?.net_weight_range &&
          Object.values(formValues?.net_weight_range).filter(
            (value) => value !== null && value !== undefined && value !== ""
          )?.length < 2))
        ? "Net Weight Range is required for Make to Order Items"
        : "";
    // formErrors.barcode = !formValues?.barcode
    //   ? "Please enter a valid Barcode"
    //   : !isNumberOnly(formValues?.barcode)
    //   ? "Please enter only digit"
    //   : formValues?.barcode?.length > MAX_STRING_LENGTH_12
    //   ? `Cannot be more than ${MAX_STRING_LENGTH_12} characters`
    //   : "";
    formErrors.images =
      formValues?.productCategory !== "f_and_b" &&
      formValues?.images?.length < 2
        ? "Minimum 2 images are required"
        : "";
    formErrors.totalSellingPrice = Number.isNaN(parseFloat(formValues?.totalSellingPrice))
      ? "Total Selling Price is Required "
      : !isAmountValid(formValues?.totalSellingPrice)
      ? "Please enter only digit"
      : "";
    formErrors.sellerPrice = Number.isNaN(parseFloat(formValues?.sellerPrice))
      ? "Selling Price is Required "
      : !isAmountValid(formValues?.sellerPrice)
      ? "Please enter only digit"
      : "";
    formErrors.makeToOrderMaxSellingPrice = !makeToOrder
      ? ""
      : Number.isNaN(parseFloat(formValues?.makeToOrderMaxSellingPrice))
      ? "Make-To-Order Max Selling Price is Required "
      : !isAmountValid(formValues?.makeToOrderMaxSellingPrice)
      ? "Please enter only digit"
      : "";
    formErrors.makeToOrderMaxPrice = !makeToOrder
      ? ""
      : Number.isNaN(parseFloat(formValues?.makeToOrderMaxPrice))
      ? "Make-To-Order Max Price is Required "
      : !isAmountValid(formValues?.makeToOrderMaxPrice)
      ? "Please enter only digit"
      : "";
  }

  if (formValues?.productCategory) {
    const subCatList = PRODUCT_SUBCATEGORY[formValues?.productCategory];
    const selectedSubCatObject = subCatList?.find(
      (subitem) => subitem.value === formValues?.productSubcategory1
    );
    if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
      const hiddenFields =
        FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
          selectedSubCatObject.protocolKey
        ];
      hiddenFields?.forEach((field) => {
        formErrors[field] = "";
      });
    } else {
    }
  } else {
  }
  console.log("Product Info form errors : ", formErrors);
  let product_info_form_validity = !Object.values(formErrors).some(
    (val) => val !== ""
  );
  return { product_info_form_errors: formErrors, product_info_form_validity };
};

export const validateDeliveryInfoForm = (deliveryFormValues, makeToOrder) => {
  const form_errors = {};
  // Fulfillment Option validation
  form_errors.fulfillmentOption = !deliveryFormValues.fulfillmentOption
    ? "Fulfillment option is required"
    : ["delivery", "pickup", "delivery&pickup"].includes(
        deliveryFormValues.fulfillmentOption
      )
    ? ""
    : "Invalid fulfillment option";
  // Dispatch Time validation
  form_errors.dispatchTime = !deliveryFormValues.dispatchTime
    ? "Dispatch time is required"
    : isNaN(deliveryFormValues.dispatchTime)
    ? "Dispatch time must be a number"
    : +deliveryFormValues.dispatchTime < 0
    ? "Dispatch time cannot be negative"
    : "";
  // Return Window validation
  form_errors.returnWindow = !deliveryFormValues.returnWindow
    ? "Return window is required"
    : isNaN(deliveryFormValues.returnWindow)
    ? "Return window must be a number"
    : +deliveryFormValues.returnWindow < 0
    ? "Return window cannot be negative"
    : "";
  // Cancellable validation
  form_errors.isCancellable =
    deliveryFormValues.isCancellable === ""
      ? "Cancellation option is required"
      : deliveryFormValues.isCancellable !== "true" &&
        deliveryFormValues.isCancellable !== "false"
      ? "Invalid cancellation value"
      : "";
  // Returnable validation
  form_errors.isReturnable =
    deliveryFormValues.isReturnable === ""
      ? "Return option is required"
      : deliveryFormValues.isReturnable !== "true" &&
        deliveryFormValues.isReturnable !== "false"
      ? "Invalid return value"
      : "";
  form_errors.makeToOrderDispatchTime =
    makeToOrder
      ? !deliveryFormValues.makeToOrderDispatchTime
        ? "Make to order dispatch time is required"
        : isNaN(deliveryFormValues.makeToOrderDispatchTime)
        ? "Make to order dispatch time must be a number"
        : +deliveryFormValues.makeToOrderDispatchTime < 0
        ? "Make to order dispatch time cannot be negative"
        : ""
      : "";
  let delivery_info_form_validity = form_errors
    ? !Object.values(form_errors).some((val) => val !== "")
    : true;
  console.log("Delivery Info form errors : ", form_errors);
  return {
    delivery_info_form_errors: form_errors,
    delivery_info_form_validity,
  };
};

export const validateVitalInfoForm = (vitalFormValues) => {
  let form_errors = {};
  // Country of Origin validation
  form_errors.countryOfOrigin = !vitalFormValues.countryOfOrigin
    ? "Country of origin is required"
    : "";
  // Manufacturer or Packer Name validation
  form_errors.manufacturerOrPackerName =
    vitalFormValues.manufacturerOrPackerName?.trim() === ""
      ? "Manufacturer or packer name is required"
      : vitalFormValues.manufacturerOrPackerName?.length > MAX_STRING_LENGTH_100
      ? `Cannot be more than ${MAX_STRING_LENGTH_100} characters`
      : "";
  // Manufacturer or Packer Address validation
  form_errors.manufacturerOrPackerAddress =
    vitalFormValues.manufacturerOrPackerAddress?.trim() === ""
      ? "Manufacturer or packer address is required"
      : vitalFormValues.manufacturerOrPackerAddress?.length >
        MAX_STRING_LENGTH_100
      ? `Cannot be more than ${MAX_STRING_LENGTH_100} characters`
      : "";
  // HSN Code validation
  form_errors.HSNCode = !vitalFormValues.HSNCode
    ? "HSN code is required"
    : isNaN(vitalFormValues.HSNCode)
    ? "HSN code must be a number"
    : "";
  //Other Mandatory Attributes Validations
  // Gender validation
  form_errors.Gender = !vitalFormValues.Gender
  ? "Gender is required"
  : !propertyEnums.gender.find(g=>g.value==vitalFormValues.Gender)
    ? "Please provide a valid gender"
    : "";
  // Colour validation
  form_errors.Colour = !vitalFormValues.Colour
  ? "Colour is required"
  : !propertyEnums.color.find(c=>c.value==vitalFormValues.Colour)
    ? "Please provide a valid colour"
    : "";
  // Size validation
  form_errors.Size = !vitalFormValues.Size
  ? "Size is required"
  : "";
  // Brand validation
  form_errors.Brand = !vitalFormValues.Brand
  ? "Brand is required"
  : "";
  // Base Metal validation
  form_errors["Base Metal"] = !vitalFormValues["Base Metal"]
  ? "Base Metal is required"
  : !propertyEnums.base_metal.find(b=>b.value==vitalFormValues["Base Metal"])
    ? "Please provide valid base metal"
    : "";
  // Plating validation
  form_errors.Plating = !vitalFormValues.Plating
  ? "Plating is required"
  : !propertyEnums.plating.find(p=>p.value==vitalFormValues.Plating)
    ? "Please provide valid plating"
    : "";

  let vital_info_form_validity = form_errors
    ? !Object.values(form_errors).some((val) => val !== "")
    : true;
  console.log("Vital Info form errors : ", form_errors);
  return { vital_info_form_errors: form_errors, vital_info_form_validity };
};

export const validateVariantsForms = (
  variantForms,
  variantFormValues,
  variationOn,
) => {
  if (variationOn === "none") {
    return {variants_info_form_errors:{}, variants_info_form_validity: true};
  }
  // else {
  //   console.log('variants fields->', variantFields);
  //   console.log('variants values->', variantFormValues);
  //   let forms_errors = variantForms?.map((variant_form) =>
  //     getFormErrors(variantFields, variantFormValues)
  //   );
  //   let has_forms_errors = forms_errors.map((form_errors) =>
  //     Object.values(form_errors).some((val) => val !== "")
  //   );
  //   let variants_info_form_validity = !has_forms_errors.some((val) => val === true);
  //   console.log("Variants Info form errors : ", forms_errors);
  //   return {variants_info_form_errors:forms_errors, variants_info_form_validity};
  // }
};
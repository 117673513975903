import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme
} from "@mui/material";
import useCancellablePromise from "../../../Api/cancelRequest";
import { getCall, postCall } from "../../../Api/axios";
import {
  getFulfillmentData,
  getShortAddress,
} from "./../../../utils/orders.js";
import cogoToast from "cogo-toast";
import { convertDateInStandardFormat } from "../../../utils/formatting/date";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import UpdateOrderStatus from "./UpdateOrderStatusModal.jsx";
import DeliveryOptionsModal from "./DeliveryOptionsModal.jsx";
import M2OUpdateModal from "./M2OUpdateModal.jsx";
import OrderItemsSummaryCard from "./OrderItemsSummaryCard.jsx";

const OrderDetails = () => {
  const [order, setOrder] = useState();
  const [user, setUser] = React.useState();
  const { cancellablePromise } = useCancellablePromise();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [trackingData, setTrackingData] = useState(null);
  // const [organizationId, setOrganizationId] = useState();
  // const [orgOnNetwork, setOrgOnNetwork] = useState(true);
  const [deliveryFulfillmentData, setDeliveryFulfillmentData] = useState();
  const [rtoFulfillmentData, setRtoFulfillmentData] = useState();
  const [isUpdateOrderModalOpen, setIsUpdateOrderModalOpen] = useState(false);
  const [isDeliveryOptionsModalOpen, setIsDeliveryOptionsModalOpen] = useState(false);
  const [isMakeToOrder, setIsMakeToOrder] = useState(false);
  const [isNetWeightUpdatedForAll, setIsNetWeightUpdatedForAll] = useState();
  const [isM2OUpdateModalOpen, setIsM2OUpdateModalOpen] = useState(false);
  const [settlementInfo, setSettlementInfo] = useState();
  const [defaultDeliveryOption, setDefaultDeliveryOption] = useState();
  const [newBaseCost, setNewBaseCost] = useState();
  const [totalOrderPrice, setTotalOrderPrice] = useState();
  const [m2oItemsJewelleryType, setM2oItemsJewelleryType] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setloading] = useState({
    accept_order_loading: false,
    cancel_order_loading: false,
    order_update_loading: false,
    delivery_option_loading: false,
    update_modal_loading: false,
    delivery_modal_loading: false,
    m2o_modal_loading: false,
  });
  const theme = useTheme();

  const getOrder = async () => {
    const url = `/api/v1/orders/${params?.id}`;
    getCall(url).then((resp) => {
      setOrder(resp);
      setDefaultDeliveryOption(resp.default_delivery_option || "");
      const m2oItemIds = resp?.makeToOrderItems?.map(item=>item.id) || [];
      //set jewelleryTypes for m2o items
      resp?.additionalFields?.map(f=>{
        if(m2oItemIds.includes(f.itemId)){
          setM2oItemsJewelleryType((prevValues)=>({
            ...prevValues,
            [f.itemId]:f.jewelleryType
          }));
        }
      })
      // setOrganizationId(resp.organization);
    });
  };

  // const getOrgDetails = async () => {
  //   const orgUrl = `/api/v1/organizations/${order.organization}`;
  //   const res = await getCall(orgUrl);
  //   const defaultDeliveryOption = res.providerDetail.storeDetails?.defaultDeliveryOption;
  //   setDefaultDeliveryOption(defaultDeliveryOption)
  //   // const onLogistic = res.providerDetail.storeDetails?.onNetworkLogistics ?? true;
  //   // setOrgOnNetwork(onLogistic); //modal only visible if org on network 
  // };

  // useEffect(() => {
  //   if (organizationId) {
  //     getOrgDetails();
  //   }
  // }, [organizationId]);

  const getSettlementInfo = async () => {
    const url = `/api/v1/settlements/settlementInfoSeller?orderId=${order?.orderId}`;
    getCall(url).then((res) => {
      console.log("settlement info: ", res);
      setSettlementInfo(res.data);
    });
  };

  const calculateNewValues = () => {
    const makeToOrderItemIds = order?.makeToOrderItems?.filter((item)=>item.price?.value).map((item) => item.id);
    const totalBreakupSum = order?.quote?.price?.value;

    const breakupItemsSum =
      order?.quote?.breakup
      ?.filter((b) => b["@ondc/org/title_type"] === "item")
      .reduce((acc, item) => {
        acc = acc + parseFloat(item?.price?.value);
        return acc;
      }, 0);
    console.log('breakup items sum->', breakupItemsSum);

    const makeToOrderItemsSum = order?.makeToOrderItems?.reduce((acc, item) => {
      if(makeToOrderItemIds.includes(item.id))
        acc = acc + parseFloat(item?.price?.value);
      return acc;
    }, 0);
    console.log('m2o items sum->', makeToOrderItemsSum);

    const nonMakeToOrderItemsSum = order?.quote?.breakup
      ?.filter(
        (b) =>
          b["@ondc/org/title_type"] === "item" &&
          !makeToOrderItemIds.includes(b["@ondc/org/item_id"])
      )
      .reduce((acc, item) => {
        acc = acc + parseFloat(item?.price?.value);
        return acc;
      }, 0);

    const newBaseCost = makeToOrderItemsSum + nonMakeToOrderItemsSum;
    const finalSum = totalBreakupSum - breakupItemsSum + newBaseCost;
    setNewBaseCost(newBaseCost);
    setTotalOrderPrice(finalSum);
  }

  useEffect(() => {
    if (params.id) getOrder();
  }, [params]);

  useEffect(() => {
    if(order?.makeToOrderItems?.length > 0){
      isMakeToOrder
        ? calculateNewValues()
        : setIsMakeToOrder(true)
    }
    const fulfillments = order?.fulfillments;
    let delivery_info = {};
    let rto_info = {};
    if (fulfillments) {
      delivery_info = getFulfillmentData(fulfillments, "Delivery");
      rto_info = getFulfillmentData(fulfillments, "RTO");
    }
    setDeliveryFulfillmentData(delivery_info);
    setRtoFulfillmentData(rto_info);
    getSettlementInfo();
  }, [order]);

  useEffect(()=>{
    let net_weight_check_flag = true;
    if(isMakeToOrder){
      order.makeToOrderItems.map((m2oitem)=>{
        //check if new price is calculated already but ignore the fashion product
        if(isNaN(m2oitem.price?.value) && m2oItemsJewelleryType[m2oitem.id]!=="fashion"){
          net_weight_check_flag = false;
        }
      })
      if(net_weight_check_flag === false)
        setIsNetWeightUpdatedForAll(false);
      else
        setIsNetWeightUpdatedForAll(true);
    }
  }, [isMakeToOrder, order]);

  useEffect(() => {
    if (isNetWeightUpdatedForAll) {
      calculateNewValues();
    }else if(isNetWeightUpdatedForAll===false) {
      setTotalOrderPrice(order?.quote?.price?.value || 0);
    }
  }, [isNetWeightUpdatedForAll]);

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    setUser(res[0]);
    return res[0];
  };

  React.useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id);
  }, []);

  const cardClass = `border-2 border-gray-200 rounded-lg p-2 bg-slate-50`;
  const price_breakup = order?.quote?.breakup;
  const offerQuotes = price_breakup?.filter(b=>b["@ondc/org/title_type"]==="offer");
  let deliveryCharges = 0;
  let convenienceCharges = 0;
  let totalBaseCost = 0;
  let order_discounts = [];
  let convenience_charges_object = {}
  let delivery_charges_object = {}

  if (price_breakup) {
    delivery_charges_object = price_breakup?.filter(
      (b) => b["@ondc/org/title_type"] == "delivery"
    );
    convenience_charges_object = price_breakup?.filter(
      (b) => b["@ondc/org/title_type"] == "misc"
    )
    offerQuotes?.map((offer)=>{
      const offerType = offer?.item?.tags?.find((tag)=>{
        return tag.code==="offer"
      }).list.find((listItem)=>{
        return listItem.code==="type"
      }).value;
      const offerId = offer?.item?.tags?.find((tag)=>{
        return tag.code==="offer"
      }).list.find((listItem)=>{
        return listItem.code==="id"
      }).value;
      order_discounts.push({
        itemId: offer?.["@ondc/org/item_id"],
        offerId,
        discount: offer?.price?.value,
        offerType
      });
    });
    if (delivery_charges_object && delivery_charges_object?.length > 0) {
      deliveryCharges = delivery_charges_object[0]?.price?.value;
    }
    if(convenience_charges_object && convenience_charges_object?.length >0){
      convenienceCharges = convenience_charges_object[0]?.price?.value;
    }
    const order_items = price_breakup?.filter(
      (b) => b["@ondc/org/title_type"] == "item"
    );
    if (order_items && order_items?.length > 0) {
      order_items?.forEach((o) => {
        totalBaseCost += parseFloat(o?.price?.value);
      });
    }
  }

  const fulfillments = order?.fulfillments;
  let delivery_info = {};
  if (fulfillments) {
    delivery_info = getFulfillmentData(fulfillments, "Delivery");
  }

  const handleCloseDeliveryOptionsModal = (isUpdated) => {
    if(isUpdated)
      getOrder();
    setIsDeliveryOptionsModalOpen(false);
};
  const handleCloseM2OUpdateModal = (isUpdated) => {
    if(isUpdated)
      handleCompleteOrderPacked(order?._id);
    setIsM2OUpdateModalOpen(false);
};
  const handleCloseStatusModal = (isUpdated) => {
    if(isUpdated)
      getOrder();
    setIsUpdateOrderModalOpen(false);
    setloading({ ...loading, update_order_loading: false });
};
  const handleCompleteOrderCancel = (order_uuid) => {
    setloading({ ...loading, cancel_order_loading: true });
    postCall(`/api/v1/orders/${order_uuid}/cancel`, {
      cancellation_reason_id: "004", //hardcoding the cancel reason id.
    })
      .then((resp) => {
        cogoToast.success("Order cancelled successfully!");
        // setInterval(function () {
          getOrder();
          // let orderData = JSON.parse(JSON.stringify(order));
          // orderData.state = resp.state;
          // setOrder(orderData);
        // }, 3000);
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error(error.response.data.error);
        // setInterval(function () {
          // }, 3000);
        })
        .finally(()=>{
        setloading({ ...loading, cancel_order_loading: false });
      })
};
  const handleCompleteOrderAccept = (order_uuid) => {
    setloading({ ...loading, accept_order_loading: true });
    const url = `/api/v1/orders/${order_uuid}/status`;
    postCall(url, {
      status: "Accepted",
    })
      .then((resp) => {
        cogoToast.success("Order accepted successfully!");
          getOrder();
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error(error.response.data.error);
        })
      .finally(()=>{
        setloading({ ...loading, accept_order_loading: false });
      })
};
  const handleCompleteOrderPacked = async(order_uuid) => {
    setloading((prev_state)=>({
      ...prev_state,
      accept_order_loading: true
    }));
    const url = `/api/v1/orders/${order_uuid}/status`;
    postCall(url, {
      status: "In-progress",
  })
    .then((resp) => {
      cogoToast.success("Order State Changed successfully!");
      console.log("Here ---->5");
      console.dir(resp,{depth:null});
      console.log("Here is the resp.sstate",resp.state);
      // let orderData = JSON.parse(JSON.stringify(order));
      // orderData.state = resp.state;
      // setOrder(orderData);
      getOrder();
    })
    .catch((error) => {
      console.log(error);
      cogoToast.error(error.response.data.error);
    })
    .finally(()=>{
      setloading((prev_state)=>({
        ...prev_state,
        accept_order_loading: false
      }))
    });
};
  const handleCompleteOrderUpdate = (order_details) => {
  setloading({ ...loading, update_order_loading: true });
  setIsUpdateOrderModalOpen(true);
};

  const renderFulfillmentStatus = (order_details) => {
    if (!order?.fulfillments.find(fulfillment => fulfillment.type === 'Delivery')) {
      return null; 
    }
  
    return (
      <span className="bg-slate-100 p-2 rounded-lg">
        <p className="text-sm font-normal text-amber-400">
          {order?.fulfillments.find(fulfillment => fulfillment.type === 'Delivery').state.descriptor.code}
        </p>
      </span>
    );
  };
  
  const renderOrderStatus = (order_details) => {
    if (
      order_details?.state == "Created" &&
      user?.role?.name !== "Super Admin"
    ) {
      return (
        <div className="mt-8 text-end">
        <Button
          className="!capitalize !mr-0.5"
          variant="contained"
          onClick={()=>setIsDeliveryOptionsModalOpen(true)}
          disabled={
            loading.delivery_option_loading
          }
        >
          {loading.delivery_option_loading ? (
              <>
                Update Delivery Option&nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Update Delivery Option</span>
            )}
        </Button>
        <div style={{ display: "flex", direction: "row", gap: "8px", marginTop: "8px" }}>
          <Button
            className="!capitalize"
            variant="contained"
            onClick={() => handleCompleteOrderAccept(order_details?._id)}
            disabled={
              loading.accept_order_loading || loading.cancel_order_loading || !order.selected_delivery_option
            }
          >
            {loading.accept_order_loading ? (
              <>
                Accept Order&nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Accept Order</span>
            )}
          </Button>
          <Button
            variant="contained"
            className="!capitalize"
            onClick={() => handleCompleteOrderCancel(order_details?._id)}
            disabled={
              loading.cancel_order_loading || loading.accept_order_loading || !order.selected_delivery_option
            }
          >
            {loading.cancel_order_loading ? (
              <>
                Cancel Order&nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Cancel Order</span>
            )}
          </Button>
          <DeliveryOptionsModal
            showModal={isDeliveryOptionsModalOpen}
            handleCloseModal={(isUpdated) => handleCloseDeliveryOptionsModal(isUpdated)}
            loading={loading}
            setloading={setloading}
            order={order}
            delivery_info={delivery_info}
            defaultDeliveryOption={defaultDeliveryOption}
          />
        </div>
        </div>
      );
    } else if (
      order_details?.state == "Accepted" &&
      user?.role?.name !== "Super Admin"
    ) {
      return (
        <div className="mt-8 text-end">
        <Button
          className="!capitalize !mr-0.5"
          variant="contained"
          onClick={()=>setIsDeliveryOptionsModalOpen(true)}
          disabled={
            loading.delivery_option_loading
          }
        >
          {loading.delivery_option_loading ? (
              <>
                Update Delivery Option&nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Update Delivery Option</span>
            )}
        </Button>
        <div style={{ display: "flex", direction: "row", gap: "8px", marginTop: "8px", justifyContent: "right" }}>
          <Button
            className="!capitalize"
            variant="contained"
            onClick={() => {
              //Note:- Another approach to handle Fashion type M2O would be to have exclude the fashion type product in order.makeToOrderItems from
              //       the [order.service.js].create() function itself, and while doing so, we can add an extra field which will consist
              //       of ids for all items similar to fashion that can be made to order but does not require net_weight etc.
              //       Once we have id's for such products, frontend can be modified to show badge for these ids but exclude them
              //       from the net_weight calculation.
              isMakeToOrder && !isNetWeightUpdatedForAll &&
              !(order?.makeToOrderItems?.length===1 && Object.values(m2oItemsJewelleryType)?.find(m=>m.jewelleryType==="fashion"))
                ? setIsM2OUpdateModalOpen(true)
                : handleCompleteOrderPacked(order_details?._id)
            }}
            disabled={
              loading.accept_order_loading || loading.cancel_order_loading
            }
          >
            {loading.accept_order_loading ? (
              <>
                Ready To Ship&nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Ready to ship</span>
            )}
          </Button>
          <DeliveryOptionsModal
            showModal={isDeliveryOptionsModalOpen}
            handleCloseModal={(isUpdated) => handleCloseDeliveryOptionsModal(isUpdated)}
            loading={loading}
            setloading={setloading}
            order={order}
            delivery_info={delivery_info}
            defaultDeliveryOption={defaultDeliveryOption}
            />
          <M2OUpdateModal
            showModal={isM2OUpdateModalOpen}
            handleCloseModal={(val) => handleCloseM2OUpdateModal(val)}
            order={order}
            loading={loading}
            setloading={setloading}
            getOrder={getOrder}
          />
        </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", direction: "row", gap: "8px" }}>
          <span className="bg-slate-100 p-2 rounded-lg">
            <p className="text-sm font-normal text-amber-400">
              {order?.state}
            </p>
          </span>
          {order_details?.selected_delivery_option==="seller_fulfilled" && order?.state!=="Completed" && (
            <div>
              <Button
                className="!capitalize"
                variant="contained"
                onClick={() => handleCompleteOrderUpdate(order_details)}
                disabled={
                  loading.accept_order_loading ||
                  loading.cancel_order_loading ||
                  loading.update_order_loading
                }
              >
                {loading.update_order_loading ? (
                  <>
                    Update Status&nbsp;&nbsp;
                    <CircularProgress size={18} sx={{ color: "white" }} />
                  </>
                ) : (
                  <span>Update Status</span>
                )}
              </Button>
              <UpdateOrderStatus
                showModal={isUpdateOrderModalOpen}
                handleCloseModal={handleCloseStatusModal}
                deliveryData={deliveryFulfillmentData}
                rtoData={rtoFulfillmentData}
                setloading={setloading}
                loading={loading}
                setOrder={setOrder}
                order={order}
              />
            </div>
          )}
        </div>
      );
    }
  };
  const handlePrintSLip = (orderId) => {
    setloading({ ...loading, accept_order_loading: true });
    const url = `/api/v1/orders/${orderId}/printSlip`;
    postCall(url)
      .then((response) => {
        console.log("Here is the repsonse", response);
        const downloadLink = document.createElement("a");
        downloadLink.href = response;
        downloadLink.download = `${orderId}.pdf`;
        downloadLink.click();
        downloadLink.remove();
        cogoToast.success("Shipment Slip Downloaded Successfully");
        window.open(response, "_blank");

        setloading({ ...loading, accept_order_loading: false });
      })
      .catch((error) => {
        cogoToast.error(error);
      });
  };
  const getLatestStatus = (orderId) => {
    setloading({ ...loading, accept_order_loading: true });
    const url = `/api/v1/orders/${orderId}/getStatus`;
    postCall(url)
      .then((response) => {
        console.log("Here is the repsonse", response);
        const orderData = response.order;
        const orderState = orderData.state;
        const trackingData = response.res;
        console.log("Here is the orderData", response.order);
        console.log("Here is the trackingData", response.res);
        console.log("Here is the tracking", response.res.tracking);
        console.log(
          "Here is the shipment status",
          response.res.shipment_status
        );

        setOrder(orderData);
        setTrackingData(trackingData);
        setIsDialogOpen(true);
        cogoToast.success("Tracking Status Fetched Successfully");
        setloading({ ...loading, accept_order_loading: false });
      })
      .catch((error) => {
        cogoToast.error(error);
      });
  };

  const TrackingDialog = ({ trackingData, onClose }) => {
    return (
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>Tracking Information</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            Current Status: {trackingData.shipment_status}
          </Typography>
          {trackingData.tracking.map((status, index) => (
            <div key={index}>
              <Typography variant="body1">{status.description}</Typography>
              <Typography variant="body2">
                Location: {status.location}
              </Typography>
              <Typography variant="body2">
                Date & Time: {status.date_time}
              </Typography>
              <hr />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getFulfillmentStatus = (order_details) => {
    if (
      order?.state === "In-progress" &&
      user?.role?.name !== "Super Admin" &&
      (order?.selected_delivery_option ?  order?.selected_delivery_option === "platform_fulfilled" : true)
    ) {
      return (
        <div style={{ display: "flex", direction: "row", gap: "8px" }}>
          <Button
            className="!capitalize"
            variant="contained"
            onClick={() => getLatestStatus(order_details?.orderId)}
            disabled={
              loading.accept_order_loading || loading.cancel_order_loading
            }
          >
            {loading.accept_order_loading ? (
              <>
                Check Order Status &nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Check Order Status</span>
            )}
          </Button>
        </div>
      );
    } else {
      return (
        <span className="bg-slate-100 p-2 rounded-lg">
          <p className="text-sm font-normal text-amber-400">{order?.state || 'Not Available'}</p>
        </span>
      );
    }
  };
  const renderInvoice = (order_details) => {
    const openInvoiceInNewTab = () => {
      let invoiceUrl = order?.documents[0]?.url;
      if (invoiceUrl) {
        window.open(invoiceUrl, '_blank');
      }
    };
  
    if (order_details?.documents[0]?.label === "Invoice" && user?.role?.name !== "Super Admin") {
      return (
        <div style={{ display: "flex", direction: "row", gap: "8px" }}>
          <Button
            className="!capitalize"
            variant="contained"
            onClick={openInvoiceInNewTab}
            disabled={
              loading.accept_order_loading || loading.cancel_order_loading
            }
          >
            {loading.accept_order_loading ? (
              <>
                Download Invoice &nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Download Invoice</span>
            )}
          </Button>
        </div>
      );
    }if (order_details?.sellerInvoice && user?.role?.name === "Super Admin") {
      return (
        <div style={{ display: "flex", direction: "row", gap: "8px" }}>
          <Button
            className="!capitalize"
            variant="contained"
            onClick={openInvoiceInNewTab}
            disabled={
              loading.accept_order_loading || loading.cancel_order_loading
            }
          >
            {loading.accept_order_loading ? (
              <>
                Download Invoice &nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Download Invoice</span>
            )}
          </Button>
        </div>
      );
    } 
    else {
      return (
        <span className="bg-slate-100 p-2 rounded-lg">
          <p className="text-sm font-normal text-amber-400">Not Available</p>
        </span>
      );
    }
  };
  
  const handlePrintShipmentSlip = (order_details) => {
    if (
      order_details?.state === "In-progress" &&
      user?.role?.name !== "Super Admin"
    ) {
      return (
        <div style={{ display: "flex", direction: "row", gap: "8px" }}>
          <Button
            className="!capitalize"
            variant="contained"
            onClick={() => handlePrintSLip(order_details?.orderId)}
            disabled={
              loading.accept_order_loading || loading.cancel_order_loading
            }
          >
            {loading.accept_order_loading ? (
              <>
                Print Shipping Label &nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: "white" }} />
              </>
            ) : (
              <span>Print Shipping Label</span>
            )}
          </Button>
        </div>
      );
    } else {
      return (
        <span className="bg-slate-100 p-2 rounded-lg">
          <p className="text-sm font-normal text-amber-400">Not Available</p>
        </span>
      );
    }
  };
  return (
    <div className="container mx-auto my-8 px-2 md:px-8">
      {isDialogOpen && trackingData && (
        <TrackingDialog
          trackingData={trackingData}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
      <BackNavigationButton onClick={() => navigate("/application/orders")} />
      <div className="flex flex-col">
        <div className={`${cardClass} my-4 p-4`}>
          <div className="flex justify-between">
            <p className="text-lg font-semibold mb-2">Order Summary</p>
            {renderOrderStatus(order)}
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">{order?.selected_delivery_option==="platform_fulfilled" ? 'Check ':''}Order Status</p>
            {getFulfillmentStatus(order)}
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Shipping Label</p>
            {handlePrintShipmentSlip(order)}
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Fulfillment Status</p>
            {renderFulfillmentStatus(order)}
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Invoice</p>
            {renderInvoice(order)}
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Order ID</p>
            <p className="text-base font-normal">{order?.orderId}</p>
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Created On</p>
            <p className="text-base font-normal">
              {convertDateInStandardFormat(order?.createdAt)}
            </p>
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Modified On</p>
            <p className="text-base font-normal">
              {convertDateInStandardFormat(order?.updatedAt)}
            </p>
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Order Status</p>
            <p className="text-base font-normal">{order?.state}</p>
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Payment Method</p>
            <p className="text-base font-normal">{order?.payment.type}</p>
          </div>
          <div className="flex justify-between mt-3 mb-3">
            <p className="text-base font-normal">Buyer name</p>
            <p className="text-md font-normal">{order?.billing?.name}</p>
          </div>
          <Divider orientation="horizontal" />
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Total Base Price</p>
            <p className="text-base font-normal">
              {!isNaN(newBaseCost)
                ? parseFloat(totalBaseCost).toFixed(2) === parseFloat(newBaseCost).toFixed(2)
                  ? parseFloat(totalBaseCost).toFixed(2)
                  : (
                    <>
                      <span className="line-through mr-1 text-slate-500">
                        ₹{parseFloat(totalBaseCost).toFixed(2)}
                      </span>
                      <span>
                        ₹{parseFloat(newBaseCost).toFixed(2)}
                      </span>
                    </>
                  )
                : parseFloat(totalBaseCost)?.toFixed(2) || "-"}
            </p>
          </div>
         
          {convenience_charges_object && convenience_charges_object?.length >0 && <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Convenience Charges</p>
            <p className="text-base font-normal">
              {parseFloat(convenienceCharges).toFixed(2)}
            </p>
          </div>}
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Total Delivery Fee</p>
            <p className="text-base font-normal">
              {parseFloat(deliveryCharges).toFixed(2)}
            </p>
          </div>
          {
            order_discounts?.map((discountObj, i)=>{
              return <div className="flex justify-between mt-3" key={"x"+i}>
              <p className="text-[#d4b48e] font-normal">Offer ({discountObj.offerId})</p>
              <p className="text-[#d4b48e] font-normal">
                {parseFloat(discountObj.discount).toFixed(2)}
              </p>
            </div>
            })
          }
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Total Price</p>
            <p className="text-base font-normal">
              {!isNaN(totalOrderPrice)
                ? parseFloat(totalOrderPrice).toFixed(2) === parseFloat(order?.quote?.price?.value).toFixed(2)
                  ? parseFloat(order?.quote?.price?.value).toFixed(2)
                  : (
                    <>
                      <span className="line-through mr-1 text-slate-500">
                        ₹{parseFloat(order?.quote?.price?.value).toFixed(2)}
                      </span>
                      <span>
                        ₹{parseFloat(totalOrderPrice).toFixed(2)}
                      </span>
                    </>
                  )
                : parseFloat(order?.quote?.price?.value)?.toFixed(2) || "-"}
            </p>
          </div>
          {isMakeToOrder && <div className="flex justify-between mt-3">
            <p className="text-base font-normal">M2O Refund Amount To Buyer</p>
            <p className="text-base font-normal">
              {order?.makeToOrderRefundAmount
                ? <span className={`${order?.makeToOrderRefundAmount?.toString().includes('-') && 'text-red-700'}`}>₹{parseFloat(order?.makeToOrderRefundAmount).toFixed(2)}</span>
                : "-"}
            </p>
          </div>}
          <div className="flex justify-between mt-3">
            <p className="text-base font-normal">Refund Amount</p>
            <p className="text-base font-normal">
              {order?.refundAmount
                ? `₹${parseFloat(order?.refundAmount).toFixed(2)}`
                : "-"}
            </p>
          </div>
        </div>
        <div className={`${cardClass}`}>
          <OrderItemsSummaryCard
            isMakeToOrder={isMakeToOrder}
            getOrder={getOrder}
            isSuperAdmin={user?.role?.name === "Super Admin" || false}
            orderItems={order?.items}
            order={order}
            setOrder={setOrder}
            m2oItemsJewelleryType={m2oItemsJewelleryType}
          />
        </div>
        <div className={`${cardClass} my-4 p-4`}>
          <div className="flex h-full">
            <div className="flex-1 mr-8">
              <p className="text-lg font-semibold mb-2">Delivery Address</p>
              <div className="flex flex-col justify-between my-3">
                <p className="text-lg font-medium">
                  {getShortAddress(delivery_info?.end?.location?.address)}
                </p>
                <p>{delivery_info?.end?.location?.address?.state}</p>
                <p>{delivery_info?.end?.location?.address?.area_code}</p>
              </div>
            </div>
            <Divider orientation="vertical" />
            <div className="flex-1 ml-8">
              <p className="text-lg font-semibold mb-2">Billing Address</p>
              <div className="flex flex-col justify-between my-3">
                <p className="text-lg font-medium">
                  {getShortAddress(order?.billing?.address)}
                </p>
                <p>{order?.billing?.address?.state}</p>
                <p>{order?.billing?.address?.area_code}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-full">
            <div className={`${cardClass} my-4 p-4`}>
              <p className="text-lg font-semibold mb-2">Customer Details</p>
              <div className="flex items-center">
                <p className="text-lg font-semibold">Name : &nbsp;</p>
                <p className="text-sm font-medium">
                  {delivery_info?.end?.person?.name}
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-lg font-semibold">Mobile Number : &nbsp;</p>
                <p className="text-sm font-medium">
                  +91 {delivery_info?.end?.contact?.phone}
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-lg font-semibold">Email : &nbsp;</p>
                <p className="text-sm font-medium">
                  {delivery_info?.end?.contact?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
        {settlementInfo && <div className="flex justify-between">
          <div className="w-full">
            <div className={`${cardClass} my-4 p-4`}>
              <p className="text-lg font-semibold mb-2">Settlement Details</p>
              {offerQuotes?.length>0 && <div className="flex justify-between">
                <p className="text-base font-normal">Seller's Total Consideration [before Offer(s)] <span className={`text-[${theme.palette.secondary.main}]`}>*</span></p>
                <p className="text-base font-normal">
                {"₹"+(parseFloat(settlementInfo.sellerTotalConsideration)
                      + Math.abs(offerQuotes
                        .reduce((acc,ofr)=>acc+=parseFloat(ofr?.price?.value), 0))).toFixed(2)}
                </p>
              </div>}
              <div className="flex justify-between">
                <p className="text-base font-normal">Seller's Total Consideration{`${offerQuotes?.length>0 ? ' [after Offer(s)]' : ''}`}</p>
                <p className="text-base font-normal">
                {"₹"+settlementInfo.sellerTotalConsideration}
                </p>
              </div>
              {(!isNaN(settlementInfo.platformDeliveryCharge) && settlementInfo.platformDeliveryCharge!==0) &&
              <div className="flex justify-between">
                <p className="text-base font-normal">Outsourced Delivery Fees</p>
                <p className="text-base font-normal">
                {"₹"+settlementInfo.platformDeliveryCharge}
                </p>
              </div>}
              <div className="flex justify-between">
                <p className="text-base font-normal">TDS Deduction</p>
                <p className="text-base font-normal">
                {"₹"+settlementInfo.tdsDeduction}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-base font-normal">TCS Deduction</p>
                <p className="text-base font-normal">{!settlementInfo.tcsDeduction
                                                      ? "₹0"
                                                      : !Number.isNaN(parseFloat(settlementInfo.tcsDeduction.igst))
                                                        ? "IGST: ₹"+settlementInfo.tcsDeduction.igst
                                                        : <div>
                                                            <p>{"CGST: ₹"+settlementInfo.tcsDeduction.cgst}</p>
                                                            <p>{"SGST: ₹"+settlementInfo.tcsDeduction.sgst}</p>
                                                          </div>}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-base font-normal">Settlement Amount To Seller</p>
                <p className="text-base font-normal">
                {"₹"+settlementInfo.settlementAmountToSeller}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-base font-normal">Seller Settlement Status</p>
                <p className="text-base font-normal">
                  {settlementInfo.sellerSettlementStatus.split('').map((letter,i)=>i===0 ? letter.toUpperCase(): letter).join('')}
                </p>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default OrderDetails;
